.EventsPricingCheckoutPageOuterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background: #f6f8fd;
}
.EventsPricingCheckoutPageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Inter";
  gap: 10px;
  background: #f6f8fd;
  margin-bottom: 40px;
}
.EventsPricingCheckoutPageHeader {
  color: var(--Natural-800, #424242);
  text-align: center;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.EventsPricingCheckoutPageSubHeader {
  color: var(--Natural-600, #757575);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}
.EventsPricingCheckoutPageMainContainer {
  width: 415px;
  padding: 20px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(136, 136, 136, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}
.EventsPricingCheckoutPageMainContainerHeaderInfo {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  color: var(--Success-400, #34d399);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 100% */
  text-transform: uppercase;
}
.EventsPricingCheckoutPageMainContainerPlanName {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  color: var(--Natural-800, #424242);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.EventsPricingCheckoutPageMainContainerPlanAmountcontainer {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
.EventsPricingCheckoutPageMainContainerPlanAmountcontaineRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: var(--Natural-600, #757575);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.EventsPricingCheckoutPageMainContainerPlanAmountcontaineRow
  span:nth-last-child(1) {
  font-weight: 700;
}
.EventsPricingCheckoutPageMainContainerCouponContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  color: var(--Natural-800, #424242);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.EventsPricingCheckoutPageMainContainerCouponToggleButton {
  width: 44px;
  height: 20px;
  flex-shrink: 0;
  border: 1px solid var(--Natural-300, #e0e0e0);
  border-radius: 100px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.EventsPricingCheckoutPageMainContainerCouponToggleButtonInside {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 5px;
  background-color: #ff5c5c;
}
.EventsPricingCheckoutPageMainContainerApplyCouponContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 12px;
}
.EventsPricingCheckoutPageMainContainerApplyCouponContainer input {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--Natural-400, #bdbdbd);
  background: #fff;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: var(--Natural-800, #424242);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.EventsPricingCheckoutPageMainContainerApplyCouponContainer button {
  display: inline-flex;
  padding: 12px 20px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--Primary-300, #ff5c5c);
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.EventsPricingCheckoutPageMainContainerApplyCouponError {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  color: var(--Primary-500, #f00);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.EventsPricingCheckoutPageMainContainerApplyCouponSuccess {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  color: var(--Success-500, #10b981);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.EventsPricingCheckoutPageMainContainerSeperator {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-wrap: nowrap;
  overflow: hidden;
  height: 10px;
  color: #bdbdbd;
  margin-top: 10px;
}

.EventsPricingCheckoutPageMainContainerFooter {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.EventsPricingCheckoutPageMainContainerPlanAppliedCouponRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: var(--Natural-600, #757575);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.EventsPricingCheckoutPageMainContainerPlanSubTotalRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: var(--Natural-800, #424242);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.EventsPricingCheckoutPageMainContainerPlanSubTotalRow span:nth-last-child(1) {
  font-weight: 700;
}
