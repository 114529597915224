/* placeholder */
@font-face {
  font-family: "Apple Chancery";
  src: url("/src/fonts/Apple-Chancery-100.ttf") format("truetype");
}
@font-face {
  font-family: "Tims Grocery Store";
  src: url("/src/fonts/Tims Grocery Store.ttf") format("truetype");
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #757575;
}

/* container types ------------------------------ */
.textfiled_container_01 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
}

.textfiled_container_02 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  position: relative;
  /* gap: 26px; */
  flex-direction: column;
}

.textfiled_container_02 > section {
  display: flex;
  align-items: center;
  gap: 26px;
}

.textfield_container_03 {
  background: transparent;
  border: 0.5px solid #475569;
  border-radius: 5px;
  /* width: 465px; */
  width: 100%;
  height: 39px;
  padding: 0 12px;
  box-sizing: border-box;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radiofiled_container_01 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload_default_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.toolTipBox_textField {
  border-radius: 4px;
  background: #121212;
  padding: 4px 21px;
  color: #bdbdbd;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  left: 101%;
  width: max-content;
  z-index: 999;
  max-width: 300px;
}

/* labels type ----------------------------------------- */
.label_type_01 {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #94a3b8;
  margin-bottom: 6px;
}

.helper_text_label_type_01 {
  color: #ff5c5c;
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.label_type_02 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #d0d0d0;
  margin-right: 10px;
}

.label_type_03 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #a0a0a0;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.label_type_04 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #bdbdbd;
  margin-bottom: 6px;
  position: relative;

  display: flex;
  align-items: center;
  gap: 7px;
}

.label_type_04 > svg {
  cursor: pointer;
}

/* input type --------------------------------------------- */
.input_type_01 {
  background: transparent;
  border: 0.5px solid #475569;
  border-radius: 5px;
  /* width: 465px; */
  width: 100%;
  height: 39px;
  padding: 0 12px;
  box-sizing: border-box;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #ffffff;
}

.input_type_01[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg)
    brightness(95%) contrast(80%);
}

.input_type_01[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg)
    brightness(95%) contrast(80%);
}

.input_type_01::placeholder {
  color: #475569;
}

.input_type_01:focus {
  outline: #2196f3 solid 1px;
}

.input_type_05 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  width: 100%;
}

.verifiedComponent01 {
  position: absolute;
  right: 13px;
  top: 66%;
}

.input_type_02 {
  height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  border: 1px dashed #000000;
  border-radius: 13px;
  cursor: pointer;
}

.input_type_02 > i {
  color: #d0d0d0;
  font-size: 25px;
  margin-bottom: 20px;
}

.input_type_02 > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  margin-bottom: 7px;
}

.input_type_02 > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.5);
}

.input_type_03 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #94a3b8;
  flex-grow: 1;
  padding: 3px;
  border: none;
  outline: none;
  margin-left: -3px;
  background-color: transparent;
}

.input_type_03::placeholder {
  color: #94a3b8;
}

.input_type_04 {
  height: 49px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px dashed #9a9a9a;
  border-radius: 8px;
  cursor: pointer;
}

div.ck-editor.ck.ck-reset {
  width: 100%;
  /* height: 175px; */
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: rgb(0 0 0);
}

/* Dropdown css ------------------------------------------------------------------------------------------- */

.dropdown_input_01 {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  cursor: pointer;
}

.dropdown_input_01 > i {
  position: absolute;
  right: 12px;
}

.dropdown_menu_options {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px 0;
  position: absolute;
  top: 70px;
  z-index: 99;
  max-height: 250px;
  overflow-y: auto;
}

.dropdown_menu_options > span {
  width: 95%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #2c1e1e;
  height: 36px;
  min-height: 36px;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-radius: 10px;
  padding: 0 12px;
  box-sizing: border-box;
  gap: 8px;
}

.dropdown_menu_options > span:hover {
  background: #e1e1e1;
}

/* radio switch css ----------------------------------------------------------------------------- */

/* 1st-------------------- */

/* The switch - the box around the slider */
.switch_type_01 {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 14px;
}

/* Hide default HTML checkbox */
.switch_type_01 input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider_type_01 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b1b1b1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider_type_01:before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  left: -1px;
  bottom: -3px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: #fafafa;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
}

input:checked + .slider_type_01:before {
  background-color: #fff;
}

input:checked + .slider_type_01 {
  background-color: #565656a1;
}

input:focus + .slider_type_01 {
  box-shadow: 0 0 1px #232426a1;
}

input:checked + .slider_type_01:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

/* Rounded sliders */
.slider_type_01.round_type_01 {
  border-radius: 10px;
}

.slider_type_01.round_type_01:before {
  border-radius: 50%;
}

/* Buttons csss ------------------------------------------ */

.button_01_css {
  min-width: 164px;
  padding: 12px 20px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: transparent;
}

.button_01_css:hover {
  background-color: red;
  border: 1px solid red;
}

.button_02_css {
  padding: 20px 40px;
  display: flex;
  align-items: center;
  gap: 10px;

  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 8px;
  background: var(--neutral-gray-800, #1e293b);
  border: none;
  width: max-content;
  cursor: pointer;
}

.button_02_css:hover {
  background-color: red;
  border: 1px solid red;
}

.button_03_css {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.button_03_css:hover {
  color: red;
}

.button_04_css {
  /* min-width: 164px; */
  padding: 12px 20px;
  border-radius: 5px;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #fafafa;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #ff5c5c;
  /* border: none; */
}

.button_05_css {
  /* min-width: 164px; */
  padding: 12px 20px;
  color: var(--Primary-300, #ff5c5c);
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid var(--Primary-300, #ff5c5c);
}

/* tags section --------------------------------------------------- */

.tags01_box {
  display: inline-flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  row-gap: 15px;
  width: 100%;
  min-height: 130px;
  border: 0.5px solid #475569;
  border-radius: 5px;
  padding: 15px;
  box-sizing: border-box;
  max-height: 172px;
}

.tags01_box > span {
  background: #e3e3e3;
  border-radius: 20px;
  display: inline-block;
  margin-right: 6px;
  text-align: center;
  box-sizing: border-box;
  padding: 4px 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.tags01_box > span > i {
  color: #a6a6a6;
  cursor: pointer;
  margin-left: 10px;
}

/* textarea type --------------------------------------------- */
.textarea_type_01 {
  background: transparent;
  border: 0.5px solid #475569;
  border-radius: 5px;
  /* width: 465px; */
  width: 100%;
  min-height: 100px;
  resize: vertical;
  padding: 2px 12px;
  box-sizing: border-box;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.textarea_type_01::placeholder {
  color: #888888;
}

.textarea_type_01:focus {
  outline: #2196f3 solid 1px;
}

/* Ck editor css ------------ */

.quill-editor {
  min-height: 135px;
  width: 100%;
  color: #ffffff;
  border: 0.5px solid #475569;
  border-radius: 10px;
  /* margin-bottom: 37px; */
}

.ql-editor.ql-blank::before {
  color: #888888 !important;
  font-style: normal !important;
}

.ql-container {
  border-radius: 0 0 5px 5px;
  border: 0.5px solid #9a9a9a !important;
  min-height: 116px;
}

.ql-toolbar {
  border-radius: 5px 5px 0 0;
  border: 0.5px solid #9a9a9a !important;
}

/* Select button ------------ */

.select_button_type_01 {
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 20px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  cursor: pointer;
  text-transform: capitalize;
}

.select_button_type_01:hover {
  background: #666;
  border: 1px solid #666;
}

.select_button_type_01_active {
  background: #666;
  border: 1px solid #666;
}

.select_button_type_02 {
  color: #bdbdbd;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 20px;
  border-radius: 4px;
  border: 1px solid #757575;
  cursor: pointer;
  text-transform: capitalize;
  border-radius: 200px;
}

.select_button_type_02:hover {
  background: #ff5c5c;
  border: 1px solid #ff5c5c;
  color: #fafafa;
}

.select_button_type_02_active {
  background: #ff5c5c;
  border: 1px solid #ff5c5c;
  color: #fafafa;
}

.tooltip-component-box {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  z-index: 1;
  top: 30px;
  left: 100%;
  transform: translateX(-50%);
  max-width: 200px; /* Adjust the maximum width as needed */
  width: max-content; /* Ensure the width only expands to fit the content */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  z-index: 999999999;
}

.tooltip-component-box > ul {
  padding-left: 20px;
  margin-top: 8px;
}

.tooltip-component-box > ul li {
  margin-bottom: 5px;
}

/* react date picker ----- */

.react-datepicker-wrapper input {
  background-color: transparent;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.react-datepicker-wrapper input::placeholder {
  color: #888888;
}

.react-datepicker-wrapper input:focus {
  outline: none;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper input {
  width: 100%;
}

/* table component css ----------- */

.table_component_wrapper01 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* head of table */
.table_component_wrapper01 > section {
  width: 100%;
  display: grid;
  border-radius: 12px 12px 0px 0px;
  background: #282828;
  padding: 18px 12px;
  box-sizing: border-box;
  place-items: center;
  column-gap: 4px;
}

.table_component_wrapper01 > section > span {
  color: #f8f8f8;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.table_component_wrapper01 > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #212121;
}

.table_component_body01 {
  width: 100%;
  display: grid;
  padding: 18px 12px;
  border-bottom: 0.5px solid #414141;
  box-sizing: border-box;
  place-items: center;
  column-gap: 4px;
}

.table_component_body01 > span {
  color: #d0d0d0;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
  text-wrap: wrap;
  overflow-wrap: break-word;
  width: 80%;
}

/*** IMAGE RESIZE ****/

.imageresize_container {
  width: 100vw;
  height: 100vh;
}
.closebutton_imageresize {
  font-size: 30px;
  position: absolute;
  right: 20px;
}

.imageresize_cropper {
  height: 85%;
  max-height: 85%;
}
.imageresize_buttons {
  height: 15%;
  padding-bottom: 5vh;
  margin: 20px;
}
.buttons_imageresize {
  margin-right: 5vw;
}
.container_imageresize {
  height: 90vh;
  width: 62vw;
  margin-right: 30vw;
}

.container-cropper {
  height: 70%;
}

.cropper {
  height: 100%;
  position: relative;
  border-radius: 20px;
}

.slider {
  height: 10%;
  display: flex;
  align-items: center;
  margin: auto;
  width: 60%;
}

.container-buttons {
  height: max-content;
  display: block;
}

.slider-imagecrop {
  width: 100%;
  display: flex;
  padding-top: 2vh;
  gap: 2vw;
  justify-content: center;
  background-color: rgb(226, 226, 226);
}
.ultimatewrapper_imageprev {
  width: 62%;
  height: 79%;
  margin: 10vh auto;
  border: 2px solid rgb(226, 226, 226);
  border-radius: 10px;
  overflow: hidden;
}
.slider-imagecrop-wrap {
  width: 60%;
}

.button-preview {
  display: flex;
  padding-top: 2vh;
  padding-bottom: 0.5vh;
  background-color: rgb(226, 226, 226);
  align-items: center;
  justify-content: center;
  gap: 5vw;
}

.button-preview > button {
  padding: 8px 15px;
  border-radius: 5px;
  background-color: black;
  border: none;
  color: white;
  font-size: 15px;
  cursor: pointer;
}

.button-preview > button:hover {
  background-color: red;
}

.imageshower {
  max-width: 300px;
  max-height: 300px;
}

.imaaaaaaaaa {
  width: 900px;
  height: 300px;
}

.warningspan_imagepreview {
  display: block;
  text-align: center;
  font-size: 12px;
  width: 100%;
  background-color: rgb(226, 226, 226);
  padding-bottom: 2vh;
  color: red;
}

.component_table-container {
  max-width: 100%;
  overflow-x: auto;
  scrollbar-width: thin;
}

.component_table-container table {
  border-collapse: collapse;
  width: 100%;
}
.component_table-container thead {
  border-radius: 12px 12px 0px 0px;
  overflow: hidden;
}
.component_table-container th,
td {
  padding: 8px;
  text-align: center;
  justify-content: center;
  overflow-y: visible;
  white-space: nowrap; /* Prevent text wrapping */
}
.component_table-container th {
  /* border-right: 0.5px solid var(--Natural-400, #b); */
  /* border-left: 0.5px solid var(--Natural-400, #bdbdbd); */
  border-right: 1px solid var(--Dark-Mode-Dark-Mode-600, #2f343f);
  border-bottom: 1px solid var(--Dark-Mode-Dark-Mode-600, #2f343f);
  border-left: 1px solid var(--Dark-Mode-Dark-Mode-600, #2f343f);
  background: var(--Dark-Mode-Dark-Mode-900, #262a36);
  color: var(--Neutral-Gray-200, #e2e8f0);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 20px;
  box-sizing: border-box;
  place-items: center;
}
.tableCellLoader{
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #475569; /* Blue */
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* .component_table-container th:first-child {
  border-radius: 4px 0px 0px 0px;
}

.component_table-container th:last-child {
  border-radius: 0px 4px 0px 0px;
  border-right: none;
} */
.component_table-container td {
  /* border: 0.5px solid var(--Natural-200, #eee); */
  background: var(--Dark-Mode-Dark-Mode-900, #131825);
  border-right: 1px solid var(--Dark-Mode-Dark-Mode-600, #2f343f);
  border-bottom: 1px solid var(--Dark-Mode-Dark-Mode-600, #2f343f);
  border-left: 1px solid var(--Dark-Mode-Dark-Mode-600, #2f343f);
  color: var(--Neutral-Gray-400, #94a3b8);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 16px 20px;
}
.tooltip-class {
  background-color: white;
  color: black;
  padding: 5px 10px;
  border-radius: 99px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.styles-module_tooltip__mnnfp {
  width: auto;
  max-width: 305px;
  display: flex;
  flex-wrap: wrap;
  text-wrap: wrap;
  height: auto;
  border-radius: 4px;
  background: var(--Natural-100, #f5f5f5);
  box-shadow: 0px 0px 16px 0px rgba(66, 66, 66, 0.2);
}
.styles-module_dark__xNqje {
  border-radius: 4px;
  background: var(--Natural-100, #f5f5f5);
  box-shadow: 0px 0px 16px 0px rgba(66, 66, 66, 0.2);
}
.customTooltip .__react_component_tooltip.type-dark {
  background-color: #e6bd32;
}
.customTooltip .__react_component_tooltip.type-dark.place-top:after {
  border-top-color: #d2ac2d;
  border-top-style: solid;
  border-top-width: 6px;
}

@media (max-width: 600px) {
  .label_type_01 {
    font-size: 12px;
  }

  .label_type_02 {
    font-size: 12px;
  }

  .label_type_03 {
    font-size: 10px;
  }

  .label_type_04 {
    font-size: 12px;
  }

  .input_type_01 {
    font-size: 10px;
    height: unset;
    padding: 16px 20px;
  }

  .input_type_03 {
    font-size: 10px;
  }

  .select_button_type_01 {
    padding: 10px 20px;
  }

  /* buttons ------ */

  .button_01_css {
    width: 100%;
    font-size: 12px;
  }

  /* tables */

  .table_component_wrapper01 {
    width: 200%;
  }

  .table_component_wrapper01 > section > span {
    font-size: 12px;
  }

  .table_component_wrapper01 > section {
    padding: 8px 12px;
    border-radius: 8px 8px 0px 0px;
  }

  .table_component_body01 > span {
    font-size: 10px;
  }

  .table_component_body01 {
    padding: 8px 12px;
  }

  /* tooltip */

  .tooltip-component-box {
    transform: unset;
    left: unset;
    right: 30px;
    font-size: 10px;
    max-width: 100px;
  }

  .ql-editor {
    font-size: 10px;
  }
}
