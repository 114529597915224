.service_category_selection_buttons {
  display: flex;
  gap: 20px;
}
.service_category_header_breaker {
  width: 100%;
  border: 1px solid #475569;
  margin: 20px 0;
}
.service_export_csv_button {
  margin: 10px 0;
}
.service_category_selection_buttons > .Selected > button {
  background-color: #ff5c5c;
  color: #f8fafc;
}
.service_export_csv_button button {
  width: auto;
  height: 32px;
  padding: 8px 12px 8px 12px;
  border: 1px solid #94a3b8;
  color: #94a3b8;
  background: transparent;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service_export_csv_button button > a {
  color: #94a3b8;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.userrequest-table .table_component_wrapper01 {
  width: 1500px;
}
.service_userlist_footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  align-items: center;
}
.serviceListFooterSaveDraftButton {
  width: auto;
  height: 42px;
  padding: 12px 20px;
  font-size: 14px;
  border: 1px solid #94a3b8;
  color: #94a3b8;
  background: transparent;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service_userlist_no_data_message {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--Neutral-Gray-200, #e2e8f0);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 100px;
}
