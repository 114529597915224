.serviceSuccess_outside_container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #00000080;
  display: flex;
  align-items: center;
  justify-content: center;
}

.serviceSuccess_container {
  width: 510px;
  height: 396px;
  left: 444px;
  top: 315px;
  background: #ffffff;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  overflow: hidden;
}

.serviceSuccessModal_cross {
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.success_tick_gif {
  width: 180px;
  /* margin-bottom: 10px; */
}

.serviceSuccess_container > section {
  min-height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.serviceSuccess_container > section > button {
  min-width: 206px;
  padding: 0 17px;
  height: 42px;
  background: #000000;
  border-radius: 13px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;

  margin-top: 28px;
  cursor: pointer;
  margin-bottom: 25px;
}

.serviceSuccess_container > div {
  background-color: #e6e6e7;
  /* height: 122px; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px;
}

.serviceSuccess_container > div > section {
  display: flex;
  align-items: center;
}

.serviceSuccess_container > div button {
  width: 85px;
  height: 31px;
  background: #cdcdcd;
  border-radius: 20px;
  border: none;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  cursor: pointer;
}

/* texts designs ----------------------------------------- */

.text_success_01_modal {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 5px;
  text-align: center;
}

.text_success_02_modal {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

.text_success_03_modal {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 10px;
}

.text_success_04_modal {
  width: 302px;
  height: 30px;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.8);
  margin-right: 15px;
  padding: 7px;
  box-sizing: border-box;
}

.text_success_05_modal {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 128.52%;
  text-align: center;
  color: #000000;
  width: 100%;
  margin-top: 30px;
}

/* Congrats popup ----------------- */

.model_outside_wrapper_success_modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.congratualtion_popup_outer {
  animation: popoutModal 0.5s ease-in-out forwards;
  background-color: #121212;
  border-radius: 14px 14px 0 0;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 40px 10%;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100vw;
  z-index: 99999999999;
  left: 0;
  bottom: 0;
}

.new_congratualtion_popup_outer {
  animation: fadeInFromZero 0.5s ease-in-out forwards;
  background-color: #121212;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 40px 80px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 800px;
  z-index: 99999999999;
}

@keyframes fadeInFromZero {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.congratualtion_popup_inside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
}

.congratualtion_popup_inside_symbol {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  color: white;
}

.congratualtion_popup_inside_symbol_middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  color: #fff;
  text-align: center;
  font-family: "Gilroy-Bold", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.congratualtion_popup_inside_symbol_middle > section {
  color: var(--neutral-gray-100, #f1f5f9);
  text-align: center;
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.new_congratualtion_popup_inside_symbol_middle {
  color: #f8f8f8;
  text-align: center;
  font-family: "Gilroy-SemiBold", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.new_congratualtion_popup_inside_symbol_middle > section {
  color: #d0d0d0;
  text-align: center;
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
}

.congratualtion_popup_inside_symbol_last {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  margin-top: 20px;
}

.congratualtion_popup_inside_symbol_last_01 {
  min-width: 100px;
  min-height: 29px;
  border-radius: 4px;
  color: white;
  border: 1px solid var(--neutral-gray-600, #475569);
  display: flex;
  padding: 12px 20px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  position: relative;
  cursor: pointer;
}

.congratualtion_popup_inside_symbol_last_01 > svg {
  position: absolute;
  right: 20px;
}

.congratualtion_popup_inside_symbol_last_button {
  padding: 12px 40px;
  border-radius: 4px;
  background: #f00;
  color: #fff;
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  border: none;
}

.chnageStatusModalCross {
  color: grey;
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
}

.chnageStatusModalCross:hover {
  color: red;
}

.button_serviceModal_creation01 {
  padding: 12px 40px;
  border-radius: 4px;
  background: #212121;
  box-shadow: 0px 4px 8px 0px rgba(31, 31, 31, 0.1);
  border: none;
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 8px;
}

.button_serviceModal_creation01:hover {
  background-color: #282828;
}

/* STatic data modal ---------------- */

.data_static_modal_main_body_box {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.data_static_modal_main_body_box > h1 {
  color: #fff;
  font-family: "Gilroy-Bold", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.data_static_modal_content_data_main_body {
  width: 100%;
  background: #212121;
  box-sizing: border-box;
  padding: 20px;

  color: #bdbdbd;
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
  border-radius: 8px;
}

.data_static_modal_content_data_main_body > h1 {
  color: #f5f5f5;
  font-family: "Gilroy-SemiBold", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.data_static_modal_content_data_main_body > ul {
  width: 90%;
  margin: 2px 0;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.button_static_modal_content_modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
  position: relative;
}

.button_static_modal_content_modal > span {
  position: absolute;
  left: 48%;
  color: var(--natural-400, #bdbdbd);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 660px) {
  .serviceSuccess_container {
    width: 90%;
    height: 300px;
  }

  .text_success_01_modal {
    font-size: 19px;
    line-height: 29px;
    width: 90%;
  }

  .text_success_02_modal {
    font-size: 15px;
  }
}
