.setup_success_wrapper {
  margin-top: 10%;
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.setup_success_content {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  gap: 20px;
}
.setup_success_content h1 {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: #e2e8f0;
  text-align: center;
}
.setup_success_content h3 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #94a3b8;
  text-align: center;
}
.setup_success_buttons {
  margin-top: 50px;
  display: flex;
  gap: 70px;
}
