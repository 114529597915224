.event_popup_wrap_up {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background-color: rgb(46 45 45 / 39%);
}

.create_event_head_popup {
  display: inline-flex;
  padding: 60px 80px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  border-radius: 20px;
  background: #f7f7f8;
  animation: popoutonscreen 0.4s ease-in-out forwards;
}

@keyframes popoutonscreen {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.create_event_head_popup_text {
  color: #000;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.create_event_head_popup_total {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 80px;
}

.create_event_head_popup_total_frame {
  display: flex;
  width: 360px;
  padding: 20px 40px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 2px 2px 8px 0px rgba(18, 18, 18, 0.08);
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 20px;
  cursor: pointer;

  color: var(--neutral-gray-600, #475569);
  text-align: center;
  font-family: "Gilroy-SemiBold", sans-serif;
  font-size: 30px;
  font-style: normal;
  line-height: normal;
}

.create_event_head_popup_total_frame:hover {
  border-radius: 8px;
  background: #121212;
  box-shadow: 2px 2px 8px 0px rgba(18, 18, 18, 0.08);
  color: white;
}

.create_event_head_popup_total_frame_text {
  text-align: center;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
