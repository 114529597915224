

.button_login_dev{
    background-color: red !important;
    text-align: center !important;
    color: white;
    font-weight: bold;
    margin-top: 25px;
    border: none;
    font-size: 1.2rem !important;
    padding: 10px 15px !important;
    margin-bottom: 15px;
}


.otp_modal_developers{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.otp_modal_developers > input{
    padding: 5px 10px;
}

.login_container_developer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    flex-direction: column;
}
