.servicestat_wrapper {
  padding: 3% 7%;
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.servicestat_heading {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.servicestat_leftheading > h1 {
  margin-bottom: 3vh;
}
.servicestat_leftheading {
  margin-bottom: 40px;
}
.servicestat_product {
}
.servicestat_product {
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
}

.servicestat_breakline {
  width: 100%;
  height: 1px;
  background-color: #00000033;
  margin-bottom: 50px;
}
.servicestat_span1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  min-width: 150px;
  color: rgba(0, 0, 0, 0.5);
}
.servicestat_button {
  cursor: pointer;
  padding: 8px 30px;
  background-color: black;
  color: white;
  border-radius: 10px;
  border: none;
}

.servicestat_statsboxwrap {
  display: flex;
  flex-wrap: wrap;
  column-gap: 35px;
  row-gap: 40px;
}

.servicestat_statsbox {
  height: 220px;
  border-radius: 10px;
  width: 190px;
  height: 180px;
  background: #f6f7fc;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
}

.servicestat_boxpa {
  margin: 10% 10%;
  display: block;
}
.servicestat_boxpa img {
  display: block;
  margin-bottom: 15px;
}
.servicestat_boxpa_div {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.5);
  min-height: 60px;
  margin-bottom: 10px;
  display: flex;
  width: 106%;
  justify-content: space-between;
}

.servicestat_boxpa > h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

.serivce_heading_00 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 20px;
  border-radius: 12px;
  background: #212121;
  box-shadow: 4px 4px 8px 0px rgba(131, 131, 131, 0.08);
}

.service_stats_page_title_section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.service_stats_page_title_section > h1 {
  color: #f8f8f8;
  font-family: "Gilroy-SemiBold", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.service_stats_page_title_section > button {
  border-radius: 2px;
  background: #282828;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  gap: 10px;

  color: #fff;
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  border: none;
}

.service_stats_page_title_section > button:hover {
  background-color: red;
}

.serivce_heading_01 {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  height: auto;
  word-break: break-word;
}
.serivce_heading_01 > img {
  width: 320px;
  height: 160px;
  min-width: 320px;
  border-radius: 4px;
}

.serivce_heading_02 {
  width: 100%;
  display: flex;
  gap: 21px;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.serivce_heading_02 > section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
}
.serivce_heading_02 > section > span {
  color: #666;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
}

.serivce_heading_02 > section > span svg {
  font-size: 18px;
}

.serivce_heading_03 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.serivce_heading_03 > button {
  display: flex;
  padding: 12px 20px;
  border-radius: 2px;
  background: #282828;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  border: none;
}

.serivce_heading_03 > button:hover {
  background: red;
}

.serivce_heading_03 > span {
  cursor: pointer;
  display: flex;
  color: #666;
  align-items: center;
  justify-content: center;
}

.serivce_heading_03 > span:hover {
  color: red;
}

.service_table_00 {
  display: flex;
  /* margin-top: 50px; */
}

.service_table_00 > table {
  width: 100%;
}
.service_table_00 > table > tr {
  border-radius: 11px 11px 0px 0px;
  background: #212121;
  display: flex;
  align-items: center;
  padding: 17.5px 12px;
  /* cursor: pointer; */
}

.hoverable-row {
  cursor: pointer;
}

.hoverable-row:hover {
  border-bottom: 0.5px solid #fff;
  background: #121212;
  color: white;
}

.hoverable-row:hover td {
  color: white;
}

.hoverable-row:hover td > h2 {
  color: white;
}

.service_table_00 > table > tr > th {
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
}

.service_table_00 > table > tr > td {
  width: 100%;
  height: 100%;
  color: #d0d0d0;
  font-family: "Inter";
  justify-content: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.service_table_00 > table > tr > td > h2 {
  color: #d0d0d0;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.referal_top_list_00 {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  height: 434px;
  gap: 30px;
  border-radius: 12px;
  background: #212121;
  box-shadow: 4px 4px 8px 0px rgba(18, 18, 18, 0.08);
}

.referal_top_list_000 {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  height: 100%;
}

.referal_top_list_00 > h1 {
  color: #d0d0d0;
  text-align: center;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
}

.referal_top_list_01 {
  /* width: 899px; */
  /* width: 100%; */
  width: 33%;
  height: 333px;
  position: relative;
  /* height: 353px; */
}
.referal_top_list_02 {
  /* width: 293px;
  height: 293px; */
  /* position: relative;
width: 100%;
height: 100%; */
}
.referal_top_list_profile {
  /* width: 120px;
  height: 120px; */
  width: 33%;
  height: 36%;
  z-index: 1;
  top: 2%;
  left: 33%;
  /* left: 7%; */
  /* right: 18%; */
  position: absolute;
  border-radius: 100%;
}
.referal_top_list_02 > section {
  min-width: 100%;
  top: 20%;
  bottom: 15%;
  min-height: 305px;
  position: absolute;
  border-radius: 8px;
  background: #282828;
}

.referal_top_list_02_referal_details {
  display: flex;
  margin-top: 99.8px;
  flex-direction: column;
}

.referal_top_list_02_referal_details > span {
  color: #d8b500;
  text-align: center;
  /* position: absolute; */
  /* z-index: 1; */
  font-family: "Inter";
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
}
.referal_top_list_profile_circle {
  width: 33px;
  height: 25.612px;
  flex-shrink: 0;
  /* position: absolute; */
  z-index: 5;
  bottom: 55%;
  left: 46%;
}
.referal_top_list_profile_circle_00 {
  position: absolute;
  top: 37%;
  right: 45%;
  width: 33px;
  height: 25.612px;
  z-index: 1;
}

.referal_top_list_profile_circle_00 > span {
  position: absolute;
  top: 5%;
  right: 34%;
  font-size: 20px;
  color: white;
}

.serivce_heading_updated_time {
  display: flex;
  align-items: center;
  gap: 12px;
}

.serivce_heading_updated_time > p {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #666;
  font-family: "Gilroy-SemiBold", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  gap: 8px;
}

@media (max-width: 600px) {

  .serivce_heading_00 {
    padding: 8px 12px ;
    width: 92vw;
    box-sizing: border-box;
    margin-top: 10px;
    border-radius: 8px;
    box-shadow: unset;
  }
  .servicestat_wrapper {
    padding: unset;
    width: 100vw;
    align-items: center;
    position: relative;
    padding-bottom: 50px;
}

  .serivce_heading_01 {
    height: 120px;
    position: relative;
  }

  .serivce_heading_01 > img {
    width: 92px;
    height: 46px;
    min-width: unset;
  }

  .serivce_heading_03{
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
  }

  .serivce_heading_02 > section  > span{
    font-size: 12px !important;
  }

  .serivce_heading_03 > button {
    padding: 8px;
    font-size: 12px;
  }

  .serivce_heading_03 > span{
    font-size: 12px;
  }

  .service_table_00{
    width: 92vw;
  }


  .serivce_heading_updated_time{
    position: absolute;
    bottom: 10px;
    right: 4%;
  }

  .serivce_heading_updated_time > p{
    font-size: 12px;
  }


  .service_table_00 > table > tr > th{
    font-size: 12px;
  }

  .service_table_00 > table > tr > td{
    font-size: 10px;
    gap: 9px;
    flex-direction: column;
  }

  .service_table_00 > table > tr > td > h2{
    font-size: 10px;
  }

}
