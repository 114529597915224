.sample_page_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sample_page_hero_Section {
  display: flex;
  padding: 40px 5% 40px 10%;
  justify-content: space-between;
  align-items: center;
  background: #13161f;
}

.sample_page_hero_Section_left_section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.sample_page_hero_Section_left_section_main_text {
  color: var(--Neutral-Gray-50, #f8fafc);
  font-family: Inter;
  font-size: 70px;
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  text-wrap: wrap;
}

.sample_page_hero_Section_left_section_sub_text {
  color: var(--Neutral-Gray-400, #94a3b8);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 140% */
  letter-spacing: -0.4px;
}

.sample_page_hero_Section_left_section button {
  display: flex;
  padding: 14px 28px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--Primary-300, #ff5c5c);
  margin-top: 20px;
  color: var(--Natural-50, #fafafa);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sample_page_hero_Section_right_section {
  width: 60%;
}

/* your event section */
.sample_page_your_event_Section {
  display: flex;
  padding: 80px 10%;
  justify-content: space-between;
  align-items: center;
  background: #13161f;
}

.sample_page_your_event_Section_right_section {
  width: 427px;
  height: 427px;
  flex-shrink: 0;
  border-radius: 427px;
  /* border: 1px solid #cbcbcb; */
}

.sample_page_your_event_Section_main_text span {
  background: var(--2, linear-gradient(121deg, #f36c6c 0%, #febfff 99.87%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Solution section */
.sample_page_solution_Section {
  display: flex;
  flex-direction: column;
  padding: 50px 10% 120px;
  justify-content: space-evenly;
  align-items: center;
  background: #0d1117;
  gap: 40px;
}

.sample_page_solution_Section_solution_container {
  display: inline-flex;
  padding: 4px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 152px;
  background: var(--Success-50, #ecfdf5);
  color: var(--Success-600, #059669);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
}

.sample_page_solution_Section_main_text {
  background: var(--2, linear-gradient(121deg, #f36c6c 0%, #febfff 99.87%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Inter;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
}

.sample_page_solution_Section_main_text>span {
  background: #e2e8f0;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sample_page_solution_Section_main_contianer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.sample_page_solution_Section_main_contianer_left_section {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
}

.sample_page_solution_Section_main_contianer_left_section_header {
  color: var(--Neutral-Gray-300, #cbd5e1);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 438px;
}

.sample_page_solution_Section_main_contianer_left_section_button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.sample_page_solution_Section_main_contianer_left_section_button {
  display: flex;
  padding: 20px;
  align-items: center;
  width: 350px;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--Dark-Mode-Dark-Mode-800, #1c212e);
  color: var(--Neutral-Gray-400, #94a3b8);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.Selected {
  border-radius: 4px;
  background: var(--Primary-300, #ff5c5c);
  color: var(--Neutral-Gray-50, #f8fafc);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sample_page_solution_Section_main_contianer_right_section {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.sample_page_solution_Section_main_contianer_right_section_header {
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: var(--2, linear-gradient(121deg, #f36c6c 0%, #febfff 99.87%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sample_page_solution_Section_main_contianer_right_section_footer {
  color: var(--Neutral-Gray-400, #94a3b8);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* fourth section preveiw section */
.sample_page_preview_Section {
  display: flex;
  justify-content: space-between;
  padding: 80px 10%;
  background: #0d1117;
}

.sample_page_preview_Section_left {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.sample_page_preview_Section_left_first_text {
  color: var(--Primary-300, #ff5c5c);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.88px;
  text-transform: uppercase;
}

.sample_page_preview_Section_left_second_text {
  color: var(--Neutral-Gray-200, #e2e8f0);
  font-family: Inter;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
}

.sample_page_preview_Section_left_second_text span {
  background: var(--2, linear-gradient(121deg, #f36c6c 0%, #febfff 99.87%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sample_page_preview_Section_left_third_text {
  color: var(--Neutral-Gray-300, #cbd5e1);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sample_page_preview_Section_left_form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sample_page_preview_Section_right {
  display: flex;
  gap: 20px;
}

.sample_page_preview_Section_right_preview_container_one {
  position: relative;
}

.sample_page_preview_Section_right_preview_container_two {
  position: relative;
  margin-top: 68px;
}

.sample_page_preview_Section_right_perview_demo_mobile_view_one {
  position: absolute;
  top: 16px;
  left: 17px;
  width: 90%;
  border-radius: 30px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sample_page_preview_Section_right img {
  width: 254px;
  height: 533px;
  flex-shrink: 0;
}

.sample_page_preview_Section_live_demo_main {
  height: 463px;
  width: 100%;
  background-color: #171e24;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sample_page_preview_Section_live_demo_banner_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 212px;
  height: 119.425px;
  flex-shrink: 0;
  background: #121212;
  margin: 40px 0 40px;
  position: relative;
}

.sample_page_preview_Section_live_demo_banner_inner_container {
  width: 158.954px;
  height: 106.326px;
  flex-shrink: 0;
  border-radius: 2.609px;
  border: 0.326px solid rgba(255, 255, 255, 0.08);
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.04) 0%,
      rgba(93, 93, 93, 0.16) 63.02%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
}

.sample_page_preview_Section_live_demo_banner_left {
  display: flex;
  flex-direction: column;
  gap: 3.8px;
  flex-wrap: wrap;
  margin-left: -9px;
}

.sample_page_preview_Section_live_demo_banner_left_event_Name {
  color: var(--Shade-White, #fff);
  font-family: Inter;
  font-size: 9.132px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.183px;
  text-align: left;
}

.sample_page_preview_Section_live_demo_banner_left_host_name {
  color: #a0a0a0;
  font-family: Inter;
  font-size: 5.218px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.104px;
  text-align: left;
}

.sample_page_preview_Section_live_demo_banner_right {
  display: flex;
  flex-direction: column;
  height: 85%;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: nowrap;
}

.sample_page_preview_Section_live_demo_banner_right_date_time_container {
  display: inline-flex;
  padding: 2.609px 5.218px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.305px;
  border-radius: 1.305px;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.06) 0%,
      rgba(93, 93, 93, 0.24) 63.02%);
}

.sample_page_preview_Section_live_demo_banner_right_date_container {
  display: flex;
  align-items: center;
  gap: 2.609px;
  color: #d0d0d0;
  text-align: center;
  font-family: Inter;
  font-size: 3.914px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sample_page_preview_Section_live_demo_banner_right_speaker_container {
  display: flex;
  position: relative;
  text-wrap: nowrap;
}

.sample_page_preview_Section_live_demo_banner_right_speaker_container span {
  display: flex;
  padding: 1.305px 16.308px 1.305px 3.262px;
  justify-content: center;
  align-items: center;
  gap: 1.305px;
  color: var(--Shade-White, #fff);
  border-radius: 1.305px;
  background: var(--New-2,
      linear-gradient(180deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(93, 93, 93, 0.4) 63.02%));
  text-align: center;
  font-family: Inter;
  font-size: 4.566px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.183px;
}

.sample_page_preview_Section_live_demo_banner_right_speaker_container span>span {
  color: #d0d0d0;
  text-align: center;
  font-family: Inter;
  font-size: 3.262px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.13px;
  background: none;
}

.sample_page_preview_Section_live_demo_banner_right_speaker_container img {
  position: absolute;
  right: -10px;
  bottom: 0;
  width: 39.138px;
  height: 39.138px;
  flex-shrink: 0;
  border-radius: 39.138px;
  border: 1.957px solid var(--New-2, rgba(255, 255, 255, 0.1));
  background: lightgray 50% / cover no-repeat;
}

.sample_page_preview_Section_live_demo_main .eventName {
  color: var(--Neutral-Gray-200, #e2e8f0);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  /* line-height: 60px; */
}

.sample_page_preview_Section_live_demo_main .speakerName {
  color: var(--Neutral-Gray-200, #e2e8f0);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 100;
  /* line-height: 60px; */
  text-align: center;
  margin-top: 20px;
}

.sample_page_preview_Section_live_demo_main button {
  display: flex;
  width: 437px;
  padding: 12px 10px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  background: var(--Primary-300, #ff5c5c);
  color: var(--Natural-50, #fafafa);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 60px;
  width: 60%;
  margin-left: 5px;
}

.sample_page_preview_Section_live_demo_banner_left_attendee_container {
  display: flex;
  gap: 1.96px;
  align-items: center;
  position: absolute;
  top: 15%;
  left: 7%;
}

.sample_page_preview_Section_live_demo_banner_left_attendee_image {
  width: 15.046px;
  height: 15.046px;
  flex-shrink: 0;
  background-color: #6262ff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Shade-White, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 6.523px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sample_page_preview_Section_live_demo_banner_left_attendee_name {
  color: var(--Shade-White, #fff);
  font-family: Inter;
  font-size: 5.218px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.209px;
}

.sample_page_preview_Section_live_demo_banner_left_attendee_info {
  display: inline-flex;
  padding: 1.305px 2.609px;
  justify-content: center;
  align-items: center;
  gap: 3.262px;
  border-radius: 1.305px;
  background: var(--New-2,
      linear-gradient(180deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(93, 93, 93, 0.4) 63.02%));
  color: var(--Shade-White, #fff);
  font-family: Inter;
  font-size: 3.262px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.13px;
}

.sample_page_preview_Section_live_demo_referral_beneifts {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sample_page_preview_Section_live_demo_referral_beneifts img {
  width: 100%;
  height: 150px;
}



/* fifth section refferal system */
.sample_page_referral_Section {
  display: flex;
  flex-direction: column;
  padding: 80px 10%;
  justify-content: space-evenly;
  align-items: center;
  background: #171e24;
}

.sample_page_referral_Section_header {
  color: var(--Neutral-Gray-200, #e2e8f0);
  text-align: center;
  font-family: Inter;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  /* 100% */
}

.sample_page_referral_Section_header2 {
  background: var(--2, linear-gradient(121deg, #f36c6c 0%, #febfff 99.87%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Inter;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
}

.sample_page_referral_Section_refereral_dashboard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 80px;
  width: 100%;
}

.sample_page_referral_Section_refereral_dashboard_left_section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 529px;
}

.sample_page_referral_Section_refereral_dashboard_left_section_first_text {
  color: var(--Neutral-Gray-300, #cbd5e1);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sample_page_referral_Section_refereral_dashboard_left_section_first_text span {
  background: var(--2, linear-gradient(121deg, #f36c6c 0%, #febfff 99.87%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sample_page_referral_Section_refereral_dashboard_left_section_second_text {
  color: var(--Neutral-Gray-400, #94a3b8);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

.sample_page_referral_Section_refereral_dashboard_left_section_third_text {
  color: var(--Neutral-Gray-300, #cbd5e1);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sample_page_referral_Section_refereral_dashboard_left_section_fourth_text {
  color: var(--Neutral-Gray-300, #cbd5e1);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sample_page_referral_Section_bottom_subtext {
  color: var(--Primary-300, #ff5c5c);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.88px;
  text-transform: uppercase;
  margin: 80px 0 20px;
}

.sample_page_referral_Section_bottom_text {
  color: var(--Neutral-Gray-200, #e2e8f0);
  font-family: Inter;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  /* 100% */
}

.sample_page_referral_Section_bottom_text span {
  background: var(--2, linear-gradient(121deg, #f36c6c 0%, #febfff 99.87%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Inter;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
}

.sample_page_referral_Section_referral_video {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sample_page_referral_Section_referral_video img {
  width: 100%;
}


/* --------- Event Testimonial - section 1 --------- */
.sample_page_testimonial_section_1 {
  display: flex;
  flex-direction: column;
  padding: 80px 10%;
  justify-content: space-evenly;
  align-items: center;
  background: #0D1117;
}

/* --------- Event Testimonial - section 2 --------- */
.sample_page_testimonial_section_2 {
  display: flex;
  flex-direction: column;
  padding: 80px 10% 40px 10%;
  justify-content: space-evenly;
  align-items: center;
  background: #0D1117;
}



/* Pricing section */
.sample_page_wrapper .table-container {
  display: none;
}

.sample_page_wrapper .pricingpaymentGatewayInfo {
  display: none;
}

.sample_page_wrapper .Billing_page {
  background: #0d1117;
}

.sample_page_wrapper .Billing_page .navbar_creator_wrapper01 {
  display: none;
}

.sample_page_wrapper .price_chart .chart {
  border-radius: 10px;
  background: var(--Dark-Mode-Dark-Mode-900, #131825);
}

.sample_page_wrapper .price_chart .selected .chart {
  border-radius: 10px;
  border: 2px solid var(--2, #f36c6c);
  background: #0d1117;
}

@media screen and (width < 600px) {

  /* Hero section */
  .sample_page_hero_Section {
    justify-content: center;
    align-items: center;
  }

  .sample_page_hero_Section {
    padding: 80px 20px;
  }

  .sample_page_hero_Section_left_section {
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .sample_page_hero_Section_left_section_main_text {
    text-align: center;
    font-size: 50px;
    line-height: 55px;
  }

  .sample_page_hero_Section_left_section_sub_text {
    color: var(--Neutral-Gray-400, #94a3b8);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 175% */
    letter-spacing: -0.32px;
  }

  .sample_page_hero_Section_left_section button {
    color: var(--Natural-50, #fafafa);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 80px;
    margin-bottom: 130px;
  }

  /* Your event section */
  .sample_page_your_event_Section {
    flex-direction: column-reverse;
  }

  .sample_page_your_event_Section_right_section {
    width: 280px;
    height: 280px;
    flex-shrink: 0;
  }

  .sample_page_your_event_Section_main_text {
    font-size: 24px;
    line-height: normal;
    margin-top: 40px;
  }

  .sample_page_hero_Section_left_section_sub_text {
    text-align: center;
  }

  .sample_page_your_event_Section {
    padding: 40px 20px;
  }

  /* Solution Section */
  .sample_page_solution_Section {
    padding: 40px 20px;
  }

  .sample_page_solution_Section_main_contianer_left_section {
    width: 100%;
  }

  .sample_page_solution_Section_main_contianer {
    flex-direction: column-reverse;
  }

  .sample_page_solution_Section_solution_container {
    font-size: 12px;
  }

  .sample_page_solution_Section_main_text {
    font-size: 24px;
    line-height: normal;
  }

  .sample_page_solution_Section_main_contianer_right_section img {
    width: 100%;
  }

  .sample_page_solution_Section_main_contianer_left_section_header {
    display: none;
  }

  .sample_page_solution_Section_main_contianer_left_section_button-container {
    width: 100%;
    margin-top: 40px;
  }

  .sample_page_solution_Section_main_contianer_left_section_button {
    width: 90%;
    color: var(--Neutral-Gray-50, #f8fafc);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
  }

  /* Preview Section */
  .sample_page_preview_Section {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .sample_page_preview_Section_right {
    transform: scale(0.6);
    margin-top: -100px;
    margin-bottom: -100px;
  }

  .sample_page_preview_Section_left_first_text {
    font-size: 16px;
  }

  .sample_page_preview_Section_left_second_text {
    font-size: 24px;
    line-height: normal;
  }

  .sample_page_preview_Section_left {
    gap: 15px;
    width: 100%;
    align-items: center;
  }

  .sample_page_preview_Section_left_third_text {
    font-size: 12px;
  }

  .sample_page_preview_Section {
    padding: 20px 20px;
  }

  .sample_page_preview_Section_left_form {
    gap: 4px;
    width: 100%;
    align-items: center;
  }

  .sample_page_preview_Section_left_form .input_type_01 {
    padding: 10px;
  }

  /* referral dashboard section */
  .sample_page_referral_Section {
    padding: 20px 20px;
  }

  .sample_page_referral_Section_refereral_dashboard {
    flex-direction: column-reverse;
  }

  .sample_page_referral_Section_header {
    font-size: 20px;
    line-height: normal;
  }

  .sample_page_referral_Section_header2 {
    font-size: 20px;
    line-height: normal;
    text-align: center;
  }

  .sample_page_referral_Section_refereral_dashboard {
    margin-top: 20px;
  }

  .sample_page_referral_Section_refereral_dashboard_right_section img {
    width: 100%;
  }

  .sample_page_referral_Section_refereral_dashboard_left_section_first_text {
    font-size: 16px;
  }

  .sample_page_referral_Section_refereral_dashboard_left_section {
    align-items: center;
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }

  .sample_page_referral_Section_refereral_dashboard_left_section_first_text span {
    font-size: 16px;
  }

  .sample_page_referral_Section_refereral_dashboard_left_section_second_text {
    font-size: 12px;
  }

  .sample_page_referral_Section_refereral_dashboard_left_section_third_text {
    font-size: 16px;
  }

  .sample_page_referral_Section_refereral_dashboard_left_section_fourth_text {
    font-size: 12px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
  }

  .sample_page_referral_Section_bottom_subtext {
    font-size: 16px;
    margin-bottom: 12px;
  }

  .sample_page_referral_Section_bottom_text {
    font-size: 24px;
    line-height: normal;
    text-align: center;
  }

  .sample_page_referral_Section_bottom_text span {
    font-size: 24px;
    line-height: normal;
  }

  .sample_page_referral_Section_referral_video {
    margin-top: 20px;
  }

  /* --------- Event Testimonial - section 1 --------- */
.sample_page_testimonial_section_1 {
  padding: 20px 20px;
}

/* --------- Event Testimonial - section 2 --------- */
.sample_page_testimonial_section_2 {
  padding: 60px 20px 30px 20px;
}


}