.edit_profile_outside_wrapper {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-left: 6%;
  background: #1c212e;
  position: relative;
}

.edit_profile_outside_wrapper_left {
  display: flex;
  width: 100%;
}
.personalinfo_wrap {
  width: 47%;
  padding-top: 40px;
  padding-right: 20px;
  box-sizing: border-box;
}

.personalinfo_top {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 3vh;
  position: relative;
  width: 100%;
}
.personalinfo_top > h1 {
  color: #f8fafc;
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
.personalinfo_top > h3 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #94a3b8;
}

.personalinfo_top span {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
}
.personalinfo_photosection {
  display: flex;
  flex-direction: column;
  height: auto;
  /* gap: 2vh;
  margin-bottom: 4vh; */
}

.personalinfo_buttonupload {
  background-color: black;
  color: white;
  padding: 8px 20px 8px 20px;
  border-radius: 10px;
  cursor: pointer;
}
.profile_info_frame_dummy_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  gap: 5px;
  background-color: #1e293b;
  color: #94a3b8;
  border-radius: 50%;
  color: var(--Neutral-Gray-400, #94a3b8);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.profile_info_frame_dummy_container svg {
  height: 24px;
  width: 24px;
}
.personalinfo_photocontainer {
  display: flex;
  flex-direction: column;
  gap: 2vw;
  justify-content: flex-start;
  align-items: baseline;
  height: auto;
  margin-bottom: 10px;
}
.personalinfo_photo {
  width: 80px;
  border-radius: 100%;
  position: relative;
}

.personalinfo_formwrap {
  gap: 0px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.perosnalinfo_pageName {
  /* display: grid;
  grid-template-columns: repeat(2,1fr); */
  gap: 20px;
  width: 100%;
}
.personalinfo_chooseStyle {
  width: 450px;
  margin-top: 10px;
}
.personalinfo_style_button {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  align-items: center;
  gap: 9px;
  margin: 10px 0 20px 0;
  width: 100%;
}
.perosnalinfo_leftform > div {
  margin-bottom: 30px;
}
.perosnalinfo_customize_url {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}
.perosnalinfo_customize_url label {
  color: var(--Neutral-Gray-400, #94a3b8);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.perosnalinfo_customize_url_wrapper {
  display: flex;
  padding: 12px 20px;
  align-items: center;
  align-self: stretch;
  border-radius: 5px;
  border: 0.5px solid var(--Neutral-Gray-600, #475569);
  margin: 10px 0 20px 0;
  color: var(--Neutral-Gray-600, #475569);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.perosnalinfo_customize_url_wrapper input {
  background-color: transparent;
  border: none;
  width: 100%;
  height: 100%;
  color: var(--Neutral-Gray-200, #e2e8f0);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.perosnalinfo_customize_url_wrapper > input:focus {
  outline: none;
}
.perosnalinfo_customize_url_wrapper > input::placeholder {
  color: var(--Neutral-Gray-200, #e2e8f0);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.perosnalinfo_tagline{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}
.personalinfo_support_info{
  display: grid;
  grid-template-columns:repeat(2,1fr);
  grid-column-gap:20px;
  margin-bottom: 20px;
}
.perosnalinfo_rightform > div {
  margin-bottom: 30px;
}

.personalinfo_aboutme {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  margin-bottom: 10px;
  margin-top: 10px;
}

.personalinfo_aboutmeinput {
  width: 98%;
  height: 140px;
  background-color: white;
  border-radius: 10px;
  border-color: rgba(0, 0, 0, 0.219);
  padding: 15px;
  resize: none;
}

.personalinfo_aboutmeinput:focus {
  outline: #2196f3 solid 1px;
}
.personalinfo_linebreak {
  width: 100%;
  height: 2px;
  background-color: #00000033;
  margin-bottom: 5vh;
}
.personalinfo_socialwrap_01 {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 42vw;
}
.personalinfo_socialwrap {
  width: 100%;
  padding-top: 40px;
}
.personalinfo_socialwrap > h2 {
  color: #f8f8f8;
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}
.personalinfo_sociallinks_wrapper{
  background-color: #262A36;
  padding: 20px;
  gap: 20px;
  border-radius: 8px;
}
.personalinfo_sociallinks_wrapper > h2{
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #94A3B8;
}
.personalinfo_sociallinks_row{
  display: grid;
  grid-template-columns: repeat(2,1fr);
}

.personalinfo_socialwrap span {
  padding-top: 10px;
}
.personalinfo_socialwrap h2 {
  margin-bottom: 5vh;
}
.personalinfo_sociallinks {
  display: grid;
  grid-template-columns: 32.3fr 1fr 0.1fr;
  gap: 10px;
  margin-bottom: 2vh;
  place-items: center;
}
.profileinfo_imagec {
  width: 80px;
  position: relative;
  height: 80px;
  border-radius: 80px;
}

.personalinfo_savebutton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.edit_profile_sample_page_button {
  padding: 12px 24px;
  color: #1e293b;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  border-radius: 4px;
  border: 1px solid #1e293b;
  background-color: white;
  position: absolute;
  right: 0px;
}

.edit_profile_sample_page_button:hover {
  background-color: rgb(237, 237, 237);
}

/* preview section  --------------- */

.live_preview_edit_profile_page {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 38%;
  height: 100%;
  position: fixed;
  right: 0;
  padding-left: 40px;
  box-sizing: border-box;
  padding-top: 40px;
  overflow-y: auto;
}

.live_preview_modal_design {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.live_preview_modal_design > section {
  position: relative;
  /* background-image: url("../../../../Utils/Images/mobile-screen.png");
  overflow: hidden;
  background-size: "contain";
  background-repeat: "no-repeat";
  background-position: "center";
  width: 100%;
  height: 100%; */
}

.live_preview_modal_design > section > img {
  width: 400px;
  /* height: 85vh; */
}

.personalinfo_photocontainer_image_upload {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
  background-color: #666666;
  border-radius: 32px;
  position: absolute;
  right: 2px;
  bottom: 2px;
}

@media (max-width: 600px) {
  .edit_profile_outside_wrapper {
    margin: unset;
    flex-direction: column;
  }

  .edit_profile_outside_wrapper_left {
    width: 92%;
    margin: 0 auto;
  }

  .personalinfo_wrap {
    width: 100%;
    padding: unset;
    padding-bottom: 60px;
    padding-top: 20px;
  }

  .personalinfo_photocontainer {
    justify-content: center;
  }

  .personalinfo_savebutton {
    background-color: #121212;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 20px 4%;
    left: 0;
    box-sizing: border-box;
  }

  .personalinfo_savebutton > div {
    width: 100%;
  }

  .personalinfo_socialwrap {
    padding-top: 20px;
  }

  .personalinfo_socialwrap > h2 {
    font-size: 16px;
  }

  .personalinfo_socialwrap_01 {
    width: 100%;
  }

  .personalinfo_sociallinks {
    grid-template-columns: 1fr 0.1fr;
  }
}
