.main_dashboard_conatiner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #f8f8fa; */
  height: 91vh;
}

.main_dashboard_conatiner > div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.text_01_dashboard {
  font-family: "Sora";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  color: #f8f8f8;
}

.text_02_dashboard {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  color: #d0d0d0;
  margin-bottom: 25px;
}

.main_dashboard_conatiner > div > section {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.dashboard_options {
  width: 100%;
  height: 88px;
  border: 0.5px solid #d0d0d0;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 8px;
  cursor: pointer;
  margin-bottom: 20px;
}

.dashboard_options:hover {
  background-color: #212121;
  border: 0.5px solid #212121;
}

.dashboard_options:hover > span {
  color: red;
}

.dashboard_options > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #f8f8f8;
}

.dashboard_options > p {
  color: #888;
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* main container box 2 */

.main_dashboard_conatiner2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 14%;
  padding-top: 16px;
  box-sizing: border-box;
  gap: 140px;
}

.stepper_outside_wrapper_dashboard {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
}

.stepper_outside_wrapper_dashboard > section {
  display: flex;
  align-items: center;
}

.main_dashboard_design_box {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.main_dashboard_design_box > section {
  width: 100%;
}

.each_step_stepper_dashboard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2px;
  width: 92px;
}

.each_step_stepper_dashboard > div {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.changeBackgroundToBlackDashboard {
  background-color: #666666;
  border: 1px solid #666666;
}

.changeBackgroundToGreyDashboard {
  border: 1px solid #d0d0d0;
}

.each_step_stepper_dashboard > span {
  color: #d0d0d0;
  font-family: "Gilroy-Bold", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  position: relative;
  top: 10px;
  text-align: center;
  text-wrap: nowrap;
}

.horizonal_bar_stepper_dashboard {
  width: 96px;
  height: 2px;
  position: relative;
  bottom: 8px;
  right: 27px;
  background: #d0d0d0;
}

/* CHECLIST -------------- */

.first_checklist_section_dashboard {
  border-radius: 0px 0px 20px 20px;
  background: #282828;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  padding-top: 44px;
  position: relative;
}

.first_checklist_section_dashboard > svg {
  position: absolute;
  font-size: 24px;
  right: 24px;
  top: 24px;
  color: #d0d0d0;
  cursor: pointer;
}

.first_checklist_section_dashboard > svg:hover {
  color: red;
}

.first_checklist_section_dashboard > h1 {
  color: #f8f8f8;
  font-family: "Gilroy-Bold", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.first_checklist_section_dashboard > p {
  color: #d0d0d0;
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.first_checklist_section_dashboard > span {
  color: #fff;
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 20px;
}

.checklist_main_container > section {
  display: flex;
  width: 100%;
  padding: 0 20px;
  flex-direction: column;
  box-sizing: border-box;
  gap: 8px;
  height: 100%;
  overflow: auto;
  padding-bottom: 40px;
}

.checklist_main_container > section::-webkit-scrollbar {
  display: none;
}

.checklist_card_container {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 4px;
  background: #282828;
  padding: 12px 20px;
  width: 100%;
  box-sizing: border-box;
}

.top_body_checklist_header {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;
}

.top_body_checklist_header::after {
  content: "\002B";
  font-size: 1.3rem;
  position: absolute;
  right: 1rem;
  cursor: pointer;
  color: white;
}

.top_body_checklist_header.active_body_checklist_header::after {
  content: "\2212";
  color: "white";
}

.top_body_checklist_header span {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top_body_checklist_header h2 {
  color: #e0e0e0;
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.top_body_checklist_header > svg {
  font-size: 25px;
  color: white;
  cursor: pointer;
  position: absolute;
  right: 10px;
}

.bottom_body_checklist_header {
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin-top: 10px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.bottom_body_checklist_header > span {
  color: #d0d0d0;
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bottom_body_checklist_header > p {
  color: #a7a7a7;
  font-family: "Gilroy-Regular", sans-serif;
  font-style: italic;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}

.bottom_body_checklist_header > ul {
  color: #666;
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 10px;
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.bottom_body_checklist_header > div {
  display: flex;
  flex-direction: row-reverse;
}

.bottom_body_checklist_header > div button {
  color: #f8f8f8;
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px 20px;
  cursor: pointer;
}

.checklist_button_desgin_dashboard {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  align-items: center;
  gap: 10px;

  padding: 12px 20px;
  background: #212121;

  position: absolute;
  right: 80px;
  bottom: 50px;
  border: none;
  cursor: pointer;
}

.checklist_button_desgin_dashboard:hover {
  background-color: red;
}

@media (max-width: 600px) {
  .main_dashboard_conatiner2 {
    height: 100%;
    padding: 20px 4%;
    width: 100%;
    gap: 20px;
    position: relative;
    padding-top: unset;
  }

  .stepper_outside_wrapper_dashboard {
    gap: 4px;
  }

  .main_dashboard_design_box {
    width: 100%;
  }

  .main_dashboard_design_box > section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }

  .dashboard_options {
    width: 100%;
    margin: unset;
    height: 61px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 0.5px solid #a0a0a0;
  }

  .dashboard_options > span {
    color: #f8f8f8;
    font-family: "Gilroy-Bold", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
  }

  .dashboard_options > p{
    font-size: 10px;
  }

  .text_01_dashboard {
    color: #f8f8f8;
    font-family: "Gilroy-Bold", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
  }

  .text_02_dashboard {
    color: #d0d0d0;
    font-family: "Gilroy-Regular", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: unset;
  }
}
