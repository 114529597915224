
/* --- Event Testimonial ----- */
.sample_page_testimonial_section {
    display: flex;
    flex-direction: column;
    padding: 50px 10% 120px;
    justify-content: space-evenly;
    align-items: center;
    background: #0D1117;
    color: white;
  }
  
  .testimonial_swiper_container {
    max-width: 100%;
    padding: 80px 0;
  }
  
  .testimonial_conatiner_card {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 50px;
    justify-content: space-between;
  }
  
  .testimonial_card_body {
    align-items: flex-start;
    /* width: 70%; */
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    max-width: 60%;
  }
  
  .testimonial_card_body .big_quote_icon {
    font-size: 80px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }
  
  .testimonial_card_body .body_content {
    color: var(--Natural-200, #EEE);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 140% */
    padding-bottom: 40px;
  }
  
  .tesimonial_card_footer {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
  
  .tesimonial_card_footer .card_user_name {
    color: var(--Natural-200, #EEE);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 140% */
    letter-spacing: -0.4px;
    color: var(--Primary-300, #ff5c5c);
  }
  
  .tesimonial_card_footer .card_user_tag {
    color: var(--Natural-400, #BDBDBD);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 140% */
    letter-spacing: -0.4px;
  }
  
  .testimonial_card_image_holder {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .testimonial_card_image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 255px;
    height: 364px;
  }
  
  .testimonial_card_image img {
    border-radius: 12px;
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: top;
  }
  
  

  @media screen and (width < 600px) {
    /* Event Testimonial */
  .sample_page_testimonial_section {
    padding: 40px 20px;;
  }

  .testimonial_swiper_container {
    padding: 50px 0;
  }

  .testimonial_conatiner_card {
    flex-direction: column-reverse;
    gap: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .testimonial_card_body {
    justify-content: center;
    align-items: center;
    max-width: 90%;
  }

  .testimonial_card_body .big_quote_icon {
    font-size: 40px;
  }

  .testimonial_card_body .body_content {
    overflow: hidden;
    color: var(--Natural-200, #EEE);
    text-align: center;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .tesimonial_card_footer {
    justify-content: center;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .tesimonial_card_footer .card_user_name {
    color: var(--Primary-300, #ff5c5c);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    letter-spacing: -0.32px;
  }

  .tesimonial_card_footer .card_user_tag {
    text-align: center;
    color: var(--Natural-400, #BDBDBD);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
    letter-spacing: -0.24px;
  }

  .testimonial_card_image {
    width: 120px;
    height: 120px;
  }

  .testimonial_card_image img {
    border-radius: 10%;
    object-fit: cover;
  }
  }