.create_form_box_create_event01 {
  /* display: grid;
      grid-template-columns: repeat(2,1fr); */
  gap: 0px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.left_section_form_create_event01 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.buttons_form_create_event01 {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0;
  gap: 44px;
}

.create_speaker_toggle {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}

.speaker_section_left_form_create_event01 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.div_left_form_create_event01 {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.div_left_form_create_event01 > section {
  border-radius: 4px;
  background: var(--Natural-900, #212121);
  width: 100%;
  display: flex;
  padding: 20px 16px;
  align-items: center;
  gap: 20px;
  box-sizing: border-box;

  color: var(--Natural-400, #bdbdbd);
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  cursor: pointer;
}

.div_left_form_create_event01 > section > img {
  width: 120px;
  height: 67.2px;
}

.left_section_form_create_event01 > img {
  width: 100%;
  height: 160px;
}

.left_section_form_create_event01 > h2 {
  color: var(--Natural-200, #eee);
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.left_section_form_create_event01 > h3 {
  color: var(--Natural-400, #bdbdbd);
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* Add speaker card ----------------- */

.add_speaker_card_create_event_01 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  background: #212121;
}

.add_speaker_card_create_event_01 > section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add_speaker_card_create_event_01 > section {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.add_speaker_card_create_event_01 > section > p {
  color: var(--Natural-400, #bdbdbd);
}

.add_speaker_card_create_event_01 > section > span {
  color: var(--Natural-400, #ff5c5c);
  cursor: pointer;
}

.add_speaker_card_create_event_01 > div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
}

/* add images card ------------------------- */

.add_images_card_wrppaer_create_event_01{
  border-radius: 8px;
  background: var(--Natural-900, #212121);
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.add_testimonial_card_wrppaer_create_event_01{
  border-radius: 8px;
  background: var(--Natural-900, #212121);
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.add_images_card_wrppaer_create_event_01 > h2 {
  color: var(--Neutral-Gray-400, #94a3b8);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}


.add_testimonial_card_wrppaer_create_event_01 > section{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
}

.add_testimonial_card_wrppaer_create_event_01 > section > h2 {
  color: var(--Neutral-Gray-400, #94a3b8);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.add_testimonial_card_wrppaer_create_event_01 > section > span{
  color: #FF5C5C;
text-align: right;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
cursor: pointer;
}
