.navbar_creator_wrapper01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0px;
  background: #121212;
  z-index: 99;
  box-sizing: border-box;
  width: 100vw;
  padding: 20px 10%;
}

.navbar_creator_wrapper01 > img {
  width: 132.107px;
  cursor: pointer;
}

.navbar_creator_wrapper01 > div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 19px;
}


.navbar_creator_wrapper01 > div > section > button {
  display: flex;
  padding: 10px 20px;
  align-items: center;
  gap: 8px;
  /* border: 1px solid rgba(160, 160, 160, 0.5); */
  border: none;
  background: transparent;
  color: #f0f0f0;
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.navbar_creator_wrapper01 > div > section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.navbar_creator_wrapper01 > div > section > button:hover {
  color: red;
}

/* new featrue button */

.new_feature_button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  background: linear-gradient(90deg, #ff0000 -4.03%, #ffd600 110.28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: none;
  padding: 9px 22px;
  cursor: pointer;
  position: relative;
}

.new_feature_button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50px;
  border: 3px solid transparent;
  border: 3px solid;
  border-image-source: linear-gradient(90deg, #ff0000 -20.1%, #ffd600 116.55%);
  background: linear-gradient(90deg, #ff0000, #ffd600) border-box;
  -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.new_feature_button > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  background: linear-gradient(90deg, #ff0000 -15.91%, #ffd600 111.36%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  left: -35px;
  top: -10px;
  animation: blink 1.8s infinite ease-in-out;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .navbar_creator_wrapper01 {
    left: 0px;
    width: 100vw;
    padding: 22px 5px;
  }

  .navbar_creator_wrapper01 > img {
    width: 91.741px;
    margin-left: 15px;
  }

  .navbar_creator_wrapper01 > div {
    gap: 6px;
  }

  .navbar_creator_wrapper01 > div > section > button {
    /* min-width: 114px; */
    height: 23px;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    padding: 0 9px;
    gap: 4px;
  }

  .new_feature_button {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    padding: 4px 12px;
  }

  .new_feature_button > span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    left: -19px;
    top: -7px;
  }

  .navbar_creator_wrapper01 > div > section{
    gap: 4px;
  }

}
