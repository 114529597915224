/* sidebar main css ------------------- */

.sidebar_main_box {
  width: 268px;
  min-width: 244px;
  background: #131825;
  height: 100vh;
  position: sticky;
  left: 0;
  top: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  padding-left: 16px;
  box-sizing: border-box;
  padding-top: 13px;
}

.logo_sidebar {
  width: 132px;
  cursor: pointer;
}

/* main part sidebar------------------------------ */

.sidebar_main_box>div {
  width: 88%;
  /* margin: auto; */
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 25px;
}

/* upper section of creator --------------- */

.sidebar_main_box>div>div {
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
}

.sidebar_main_box>div>div>span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #0083fe;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 12px;
  border: 1px solid #0083fe;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
}

.sidebar_main_box>div>div>p {
  color: #f59e0b;
  text-align: center;
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 10px;
}

.sidebar_main_box>div>div>button {
  border-radius: 4px;
  background: var(--Secondary-400, #3460dc);

  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px 20px;

  display: flex;
  align-items: center;
  gap: 8px;
  margin: 10px auto;
  margin-top: 20px;
}

.sidebar_main_box>div>div>span img {
  margin-right: 5px;
}

/* text styles sidebar ------------------------------------------------ */

.text_sidebar_01 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.text_sidebar_email {
  font-family: "Inter";
  display: inline-block;
  max-width: 130px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;


  .text_sidebar_email_01 {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 17px;
    color: #f8f8f8;
    display: inline-block;
  }
}

.text_sidebar_02 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #d0d0d0;
}

/* creator profile sidebar------------------------------------ */

.creator_sidebar_details {
  display: flex;
  justify-content: flex-start;
  /* background-color: red; */
}

.creator_sidebar_image {
  width: 60px;
  height: 60px;
  filter: drop-shadow(1px 3px 9px rgba(0, 0, 0, 0.15));
  border-radius: 100px;
  margin-right: 12px;
}

.creator_sidebar_details>div>div {
  cursor: pointer;
  margin-top: 5px;
}

.reviews_from_sidebar:hover {
  color: red;
}

/* sidebar navigation ------------------------------------------------------- */

.sidebar_navigation {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
}

.sidebar_navigation_normal {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #e1e1e1;
  cursor: pointer;
  border-radius: 8px;
  box-sizing: border-box;
  padding-left: 13px;
  text-decoration: none;
}

.sidebar_navigation>a:hover {
  color: #f8f8f8;
}

.sidebar_navigation>a img {
  margin-right: 15px;
  width: 24px;
  height: 24px;
  color: #f8f8f8;
}

.sidebar_navigation_active {
  background: #212121;
  color: #f8f8f8;
}

/* invite section sidebar -------------------------------------------------------- */

.invite_sidebar {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 85%;
  margin: auto;
  position: absolute;
  bottom: 8px;
  left: 15px;
}

.invite_sidebar>h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #f8f8f8;
  text-transform: uppercase;
}

.invite_sidebar>span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #d0d0d0;
  margin-top: 8px;
}

.invite_sidebar>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #212121;
  border-radius: 6px;
  margin-top: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #f8f8f8;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
}

/* badges ---- */

.badges_sidebar_dashboard {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin-top: 4px;
}

.badges_sidebar_dashboard>span {
  width: 30.883px;
  position: relative;
  left: -6px;
}

.badges_sidebar_dashboard>span img {
  width: 100%;
  cursor: pointer;
  /* height: 28px; */
}

/* mobile ui  */

@media (max-width: 600px) {
  .sidebar_main_box {
    /* position: fixed;
    bottom: 0; */
    width: 100%;
    height: unset;
    min-width: unset;
    left: unset;
    top: unset;
    bottom: 0;
    background: #212121;
    box-shadow: 0px -4px 4px 0px rgba(255, 255, 255, 0.1);
    padding: unset;
    z-index: 9999;
  }

  .logo_sidebar {
    width: 91.741px;
  }

  .sidebar_navigation {
    flex-direction: row;
    padding: 12px 40px;
    box-sizing: border-box;
    margin: unset;
    overflow: hidden;
    border: unset;
    justify-content: space-between;
  }

  .sidebar_navigation>a img {
    margin: unset;
  }

  .sidebar_navigation_normal {
    flex-direction: column;
    padding: unset;
    width: unset;
    height: unset;
    gap: 4px;
    margin: unset;

    color: #a0a0a0;
    font-family: "Gilroy-Medium", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .sidebar_navigation_active {
    background-color: unset;
    color: #d0d0d0;
  }

  .invite_sidebar {
    width: 92%;
    bottom: 20px;
    left: unset;
  }
}

/*  sidebar 2 css */

.sidebar2_main_box {
  background: #121212;
  height: 100vh;
  position: sticky;
  left: 0;
  top: 0;
  border-right: 1px solid #212121;
  padding-left: 16px;
  box-sizing: border-box;
  padding-top: 13px;
  padding: 20px;
}

.sidebar2_main_box>div>img {
  width: 20px;
  cursor: pointer;
}

.sidebar2_navigation {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.sidebar2_main_box>div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.sidebar2_navigation_normal {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e1e1e1;
  cursor: pointer;
  border-radius: 4px;
  box-sizing: border-box;
  text-decoration: none;
  padding: 8px;
  position: relative;
}

.sidebar2_navigation_normal>img {
  width: 20px;
  height: 20px;
}

.sidebar2_navigation_normal:hover {
  background-color: #212121;
}

.sidebar2_navigation_active {
  background-color: #212121 !important;
}