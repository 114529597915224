.template_wrapper_00 {
  display: flex;
  padding: 40px 80px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  background: #121212;
}

.template_wrapper_00 > span > h1 {
  color: #fff;
  font-family: "Gilroy-SemiBold", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 8px;
}

.template_wrapper_00 > span > p {
  color: #e0e0e0;
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.template_wrapper_00_sharing {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 12px;
  width: 90%;
}

.template_wrapper_00_sharing > span {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  color: #fff;
  font-family: "Gilroy-Bold", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.template_wrapper_00_sharing > section {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.Card_wrapper_01 {
  width: 390px;
  display: flex;
  position: relative;
  min-height: 470px;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
  border-radius: 12px;
  background: #282828;
}

.Card_wrapper_01 > img {
  width: 100%;
  object-fit: cover;
  min-height: 100px;
}

.Card_wrapper_01 > section {
  color: #fff;
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 50px;
  line-height: 20px; /* 142.857% */
}

.Card_wrapper_01 > button {
  position: absolute;
  bottom: 20px;
  width: 90%;
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid #A0A0A0;
  background-color: transparent;
  cursor: pointer;
}
.Card_wrapper_01 > button:hover {
  background: red;
  border: 1px solid red;
  color: #f0f0f0;
}
.template_wrapper_00_sharing > span {
  color: #d0d0d0;
  font-family: "Gilroy-Bold", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.template_wrapper_00_sharing > p {
  color: #e0e0e0;
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}
