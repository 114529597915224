.servicelist-wrapper {
  width: 90%;
  height: 100vh;
  padding: 0 5% 3% 5%;
  padding-top: 30px;
  background-color: #1c212e;
  overflow-x: hidden;
}
.servicelist-headers_section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.servicelist-headers_section h1 {
  margin: 0;
}
.servicelist-wrapper .servicelist-headers_section > h1 {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #f8fafc;
  -webkit-user-select: none;
}
.content_cards_selection_button {
  display: flex;
  gap: 20px;
}
.content_cards_selection_button button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #94a3b8;
}
.content_cards_selection_button .Selected {
  font-weight: 700;
  color: #e2e8f0;
}
.content_cards_breaker {
  margin-top: 20px;
  width: 100%;
  border: 1px solid #475569;
}
.servicelist_wrap_create_button button {
  width: 192px;
}

.servicelist-categories {
  display: flex;
  gap: 2.5vw;
  margin-top: 6vh;
  color: #d0d0d0;
}

.servicelist-catItem {
  padding-bottom: 15px;
  padding-left: 5px;
  padding-right: 5px;
}

.servicelist-catItem:hover {
  padding-bottom: 13px;
  border-bottom: 2px solid white;
  cursor: pointer;
}
.selectedlist {
  padding-bottom: 13px;
  border-bottom: 2px solid white;
  cursor: pointer;
}

.servicelist-linebreak {
  position: absolute;
  margin-top: -1px;
  border-top: 1px solid #00000033;
  width: 75.5%;
}

.servicelist-table {
  margin-top: 2vh;
  -webkit-user-select: none;
  margin-bottom: 13%;
  position: relative;
}

.opacity-layer-over-table {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #121212c4;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  color: #d0d0d0;
}

.servicelistbannerimg {
  width: 60px;
  height: 30px;
  border-radius: 2px;
}

.usericonservicelist {
  padding-left: 6px;
  margin-top: 3px;
}

.servicelist_icon {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border-radius: 50%;
}
.servicelist_icon :hover {
  cursor: pointer;
}

.iconalign {
  justify-content: center;
  transform: translateX(-10%);
}
.servicelist_getdate {
  display: block;
}

.MuiTableHead-root {
  border-radius: 8px 6px 0px 0px;
  background: #282828;
  color: white;
  border-bottom: unset;
}

.MuiTableCell-head {
  color: white !important;
}

.MuiTableBody-root {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
  background: #212121;
}

.MuiTableCell-body {
  color: var(--neutral-gray-800, #f8f8f8) !important;
  font-family: "Inter" !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.servicelist_optionspopup {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 99;
  border: 1px solid black;
  border-radius: 10px;
  display: none;
  width: 200px;
  background-color: #ffffff;
  height: unset;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
}

.servicelist_wrap {
  margin: 5% 5%;
  height: 100%;
}

.servicelist_popuptop {
  height: 65%;
  width: 100%;
}
.modaloptions_servicelist {
  padding: 10px 0px 10px 5px;
  cursor: pointer;
  border-radius: 5px;
  text-align: left;
}
.modaloptions_servicelist:hover {
  background-color: #00000034;
}

.modaloptions_servicelist_status {
  padding: 10px 0px 10px 5px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  gap: 20%;
  align-items: center;
}

.servicelist_popupbottom {
  width: 100%;
}
.servicelist_popupactive {
  padding: 10px 0px 10px 5px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10%;
}

/* Events data ---------- */

.user_dashboard_event_data_section_wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  margin-bottom: 40px;
}

.live_events_wrapper_user_dashboard_page {
  display: flex;
  align-items: center;
  border-radius: 16px;
  background: #212121;
  box-shadow: 0px 0px 8px 0px rgba(18, 18, 18, 0.1);
  padding: 20px;
  box-sizing: border-box;
  width: 452px;
  height: 170px;
}

.live_events_wrapper_user_dashboard_page > div {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.live_events_wrapper_user_dashboard_page > div > span {
  color: #f00;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.live_events_wrapper_user_dashboard_page > div > section {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  overflow: hidden;
  color: var(--neutral-gray-800, #1e293b);
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.live_events_wrapper_user_dashboard_page > div > section > img {
  border-radius: 4px;
  width: 44%;
  height: 100px;
}

.live_events_wrapper_user_dashboard_page > div > section > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
}

.live_events_wrapper_user_dashboard_page > div > section > div > span {
  cursor: pointer;
  color: #d0d0d0;
}

.live_events_wrapper_user_dashboard_page > div > section > div button {
  padding: 8px 20px;
  border-radius: 4px;

  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  color: white;
  border: 1px solid #f00;
  background: #f00;
}

.live_events_wrapper_user_dashboard_page > div > section > div button:hover {
  color: #f00;
  border: 1px solid #f00;
  background: transparent;
}

.upcoming_events_wrapper_user_dashboard_page {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 16px;
  background: #212121;
  box-shadow: 0px 0px 8px 0px rgba(18, 18, 18, 0.1);
}

.upcoming_events_wrapper_user_dashboard_page > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.upcoming_events_wrapper_user_dashboard_page > div span {
  border-radius: 4px;
  background: #282828;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;

  color: #d0d0d0;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 100% */
}

.upcoming_events_wrapper_user_dashboard_page > section {
  width: 100%;
  overflow-x: auto;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 10px 2px;
  height: 450px;
  box-sizing: border-box;
}

.upcoming_events_wrapper_user_dashboard_page > section::-webkit-scrollbar {
  display: none;
}

.upcoming_event_cards_user_dashboard_event_page {
  min-width: 20vw;
  max-width: 20vw;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  background: #282828;
  box-shadow: 0px 0px 8px 0px rgba(18, 18, 18, 0.1);
  gap: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
}

.upcoming_event_cards_user_dashboard_event_page > img {
  width: 100%;
  max-height: 120px;
  border-radius: 4px;
}

.upcoming_event_cards_user_dashboard_event_page > span {
  color: #f8f8f8;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.upcoming_event_cards_user_dashboard_event_page > span:hover {
  color: purple;
}

.upcoming_event_cards_user_dashboard_event_page > div {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  color: #d0d0d0;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  bottom: 100px;
}

.upcoming_event_cards_user_dashboard_event_page > div > span {
  display: flex;
  align-items: center;
  gap: 8px;
}

.upcoming_event_cards_user_dashboard_event_page > button {
  width: 85%;
  padding: 12px 20px;
  box-sizing: border-box;
  border-radius: 4px;
  background: transparent;
  color: #a0a0a0;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  cursor: pointer;
  border: 1px solid #a0a0a0;
  bottom: 30px;
  position: absolute;
}

.upcoming_event_cards_user_dashboard_event_page > button:hover {
  background: red;
  color: #f8f8f8;
  border: 1px solid red;
}

/* MOdal csss -------------------- */

.service_share_link_user_wrapper {
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.service_share_link_user_popup {
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  pointer-events: auto;
  animation: fadeInModal 0.5s ease forwards;
  opacity: 0;
}

@keyframes fadeInModal {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.service_share_link_user {
  display: flex;
  width: 900px;
  padding: 40px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 20px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #121212;
}

.service_share_link_user_first_frame {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.service_share_link_user_first_frame > img {
  width: 400px;
  height: 200.185px;
  min-width: 400px;
}

.service_share_link_user_first_frame_text {
  color: var(--neutral-gray-300, #cbd5e1);
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 22px;
  font-style: normal;
}

.service_share_link_user_first_frame_close {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
  cursor: pointer;
  color: grey;
}

.service_share_link_user_second_frame {
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}

.service_share_link_user_second_frame_link {
  cursor: pointer;
  position: relative;
  user-select: none;
  color: var(--neutral-gray-200, #e2e8f0);
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  min-height: 28px;
  display: flex;
  padding: 12px 20px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 4px;
  border: 1px solid var(--neutral-gray-600, #475569);
}

.service_share_link_user_second_frame_link.clicked {
  color: white;
  background-color: #333;
}

.service_share_link_user_second_frame_link > svg {
  cursor: pointer;
  position: absolute;
  right: 10px;
  color: white;
}

.service_share_link_user_second_frame_allshare {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.service_share_link_user_second_frame_allshare_container {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #212121;
}

.service_share_link_user_second_frame_allshare_container > svg {
  cursor: pointer;
}

.cta_dummy_data {
  position: absolute;
  bottom: -140px;
}

/* My content card --------- */

.mycontent_card_for_service {
  width: auto;
  display: flex;
  align-items: flex-start;
  gap: 24px;
  border-radius: 12px;
  flex-direction: column;
  background: #131825;
  padding: 20px;
  box-sizing: border-box;
  /* height: 220px; */
}

.mycontent_card_for_service > img {
  height: 180px;
  min-width: 360px;
  max-width: 360px;
  background-color: rgb(40, 40, 40);
  object-fit: cover;
  cursor: pointer;
}

.mycontent_card_for_service > section {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 160px;
  word-break: break-all;
  width: 100%;
}

.mycontent_card_for_service > section > div h2 {
  color: #d0d0d0;
  font-family: "Gilroy-SemiBold", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  width: 90%;
  cursor: pointer;
}

.mycontent_card_for_service > section > svg {
  position: absolute;
  right: 0px;
  top: 0px;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.mycontnet_card_header_buttons {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.mycontent_card_flags {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 0 10px;
  gap: 10px;
  height: 23px;
  background-color: #d0d0d0;
  border-radius: 1000px;
}
.mycontent_card_right_buttons {
  display: flex;
  gap: 20px;
}
.mycontent_card_right_buttons button {
  border: 0.5px solid #94a3b8;
  color: #94a3b8;
}
.mycontent_card_right_joining_buttons button {
  border: 1px solid #ff5c5c;
  color: #ff5c5c;
}
.joining-link-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #121212; /* semi-transparent black background */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  z-index: 1000;
  color: white;
}

.joining-link-popup-content {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 600px; /* Set a maximum width for the content if needed */
}

.joining-link-popup p {
  margin-bottom: 10px;
}

.joining-link-popup button {
  /* padding: 10px; */
  background-color: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
}

.mycontent_card_content {
  width: 100%;
  display: flex;
  border: 1px solid #262a36;
  border-radius: 7px;
}
.mycontent_card_content section {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-right: 1px solid #262a36;
  color: #94a3b8;
  word-wrap: break-word;
  gap: 10px;
  flex-grow: 1;
}
.mycontent_card_content .mycontent_card_content_image {
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  gap: 10px;
}
.mycontent_card_content_image img {
  width: 146.92px;
  height: 80px;
}
.mycontent_card_content .mycontent_card_content_time {
  justify-content: space-between;
}
.mycontent_card_content section > h3 {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  text-wrap: nowrap;
}
.mycontent_card_content section > h1 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
.mycontnet_card_footer_buttons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 20px;
}
.mycontnet_card_footer_buttons_2 {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 20px;
}
.mycontnet_card_footer_buttons button {
  border: 0.5px solid #94a3b8;
  color: #94a3b8;
}
.props_mycontent_card_service {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.props_mycontent_card_service > section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.props_mycontent_card_service > section > p {
  display: flex;
  align-items: center;
  gap: 12px;

  color: #fff;
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.props_mycontent_card_service > section > p > span {
  color: #d0d0d0;
}

.props_mycontent_card_service > section > button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 4px 8px;

  color: #ff2e2e;
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  border-radius: 4px;
  background: #282828;
  border: none;
  margin-top: 20px;
}

.buttons_div_section_mycontent_card {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 11px;
}

.buttons_div_section_mycontent_card > button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 4px 8px;

  color: #d0d0d0;
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  border-radius: 2px;
  background: #282828;
  border: none;
}

.buttons_div_section_mycontent_card > button:hover {
  background-color: red;
}

.content_cards_main_wrapper_servicelist {
  width: 100%;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.certificate_preview_system {
  border-radius: 12px;
  background: var(--natural-900, #212121);
  padding: 20px 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 600px) {
  .servicelist-wrapper {
    width: unset;
    padding: unset;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .content_cards_main_wrapper_servicelist {
    width: 91vw;
    margin: 20px auto;
  }

  .mycontent_card_for_service {
    flex-direction: column;
    height: unset;
    padding: 12px;
    border-radius: 8px;
  }

  .mycontent_card_for_service > img {
    width: 100%;
    min-width: unset;
    max-width: unset;
    height: unset;
  }

  .props_mycontent_card_service {
    flex-direction: column;
    gap: 20px;
  }

  .buttons_div_section_mycontent_card {
    align-items: unset;
  }

  .mycontent_card_for_service > section {
    height: unset;
  }

  .props_mycontent_card_service > section {
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .props_mycontent_card_service > section p {
    font-size: 12px;
    gap: 6px;
    color: #a0a0a0;
  }

  .props_mycontent_card_service > section > p > span {
    color: #a0a0a0;
  }

  .buttons_div_section_mycontent_card > button {
    padding: 12px 20px;
    justify-content: space-between;
  }

  .mycontent_card_for_service > section > div > h2 {
    font-size: 16px;
    color: #a0a0a0;
  }

  .servicelist-catItem {
    font-size: 12px;
  }
}
