.payment_info_main_container {
  width: 90%;
  padding: 0 5% 3% 5%;
  padding-top: 30px;
}

/* text types ------------------------ */

.text_type01_payment_info {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 36px;
  color: #f0f0f0;
  -webkit-user-select: none;
}

/* headers paymentInfo section ----------------------- */

.headers_section_paymentInfo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

/* .headers_section_paymentInfo > button {
  width: 176px;
  height: 36px;
  background: #000000;
  border-radius: 8px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  cursor: pointer;
  border: none;
} */

/* details form */

.main_details_section_paymentInfo {
  width: 60%;
}

.main_details_section_paymentInfo > div {
  margin-bottom: 25px;
}
