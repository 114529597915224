.main_footer_section {
  width: 100vw;
  height: max-content;
  background-color: #101010;
  box-sizing: border-box;
  padding: 15px;
  color: rgba(255, 255, 255, 0.753);
  z-index: 9999999;
}

.main_footer_section > div {
  width: 85%;
  margin: auto;
}

.upper_footer_section {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  margin-bottom: 30px;
}

.anchors_details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.logo_footer {
  width: 180px;
  margin-right: 5px;
}

.anchors_details > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: rgb(255, 255, 255);
  margin-bottom: 20px;
  width: 60%;
}

.anchors_details > button {
  margin-top: 30px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  height: 44px;
  background: #ff0000;
  border-radius: 6px;
  border: none;
  padding: 0px 20px;
  cursor: pointer;
}

.footer_support_menu {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

.footer_support_menu span {
  cursor: pointer;
}

.footer_support_menu a {
  color: #ffffff;
  margin-bottom: 15px;
  cursor: pointer;
  text-decoration: none;
}

.footer_support_menu span {
  color: #ffffff;
  margin-bottom: 15px;
  cursor: pointer;
  text-decoration: none;
}

.some_extra {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 80px;
}

.some_extra span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  margin: 2px 0;
}

.lower_footer_section {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  margin: 10px 0;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  padding-top: 15px;
  color: #ffffff;
  margin-left: -15px;
}

.india_logo {
  width: 25px;
}

/* user side footer section ------------------------------------------------- */

.footer_user_side_anchors {
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #f1f1f2;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-user-select: none;
  padding-top: 8px;
}

.footer-section_logo_02 {
  width: 196px;
  height: 35px;
  position: absolute;
  left: 110px;
}

.lower_footer_section_02 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #000000;
  display: flex;
  align-items: center;
}

/* Footer dark theme New css ------------------- */

.footer_user_side_anchors2 {
  background-color: #101010;
  width: 100vw;
  height: 98px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: white;
  position: relative;
  padding-top: 40px;
}

.footer_user_side_anchors2 > img {
  width: 132.107px;
  height: 36px;
  position: absolute;
  left: 10%;
}

.footer-section_logo_02 > svg {
  cursor: pointer;
}

.footer_user_side_anchors2 > span {
  color: #fff;
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-section_logo_02 > a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #ffffff;
}

@media (max-width: 600px) {
  .main_footer_section > div {
    width: 90%;
  }

  .upper_footer_section {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }

  .anchors_details {
    align-items: center;
    padding-bottom: 15px;
    width: 100%;
    border: none;
  }

  .anchors_details span {
    font-weight: 400;
    font-size: 16px;
    line-height: 148.02%;
    text-align: center;
    width: 100%;
    margin-bottom: unset;
  }

  .anchors_details button {
    font-size: 1rem;
    width: unset;
    height: 48px;
    border-radius: 6px;
    padding: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #ffffff;
  }

  .footer_support_menu {
    align-items: center;
    flex-direction: column;
    gap: 16px;
    margin-top: 20px;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }

  .footer_support_menu a {
    margin-bottom: 12px;
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
  }

  .footer_support_menu span {
    margin-bottom: 12px;
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
  }

  .lower_footer_section {
    font-size: 0.8rem;
    width: 100vw;
    /* margin-bottom: 80px; */
  }

  .logo_footer {
    margin-top: 40px;
    margin-right: unset;
    width: 136px;
  }

  .some_extra {
    align-items: center;
    margin-top: 40px;
  }

  /* user side footer --------------------------------------- */

  .footer-section_logo_02 {
    position: unset;
    left: unset;
    margin-bottom: 10px;
    width: 196px;
    height: 35px;
  }

  .footer_user_side_anchors {
    flex-direction: column;
    border: none;
  }

  .lower_footer_section_02 {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }

  .footer_user_side_anchors2 {
    gap: 8px;
    flex-direction: column;
    height: 90px;
  }

  .footer_user_side_anchors2 > img {
    position: unset;
    left: 0;
    width: 91.741px;
    height: 25px;
  }

  .footer_user_side_anchors2 > span{
    font-size: 10px;
  }
}
