


.error_pages_wrapper{
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    text-align: center;
}