.eventsPurchaseCreditsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #424242;
}
.eventsPurchaseCreditsHeader {
  font-weight: 700;
  font-size: 40px;
  color: #424242;
}
.eventsPurchaseCreditsSubheader {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}
.eventsPurchaseMainContainter {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-style: solid;
  margin-top: 20px;
  border-color: 1px;
  border-color: #e0e0e0;
  padding: 20px 40px;
  gap: 20px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}
.eventsPurchaseCreditsMainContainerOptions {
  display: flex;
  gap: 20px;
}
.eventsPurchaseCreditsCustomInput {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 100px;
  border-color: 1px;
  border-color: #75757560;
  padding: 0 10px;
  gap: 8px;
  border-radius: 198px;
}
.eventsPurchaseCreditsOrderSummaryheader {
  color: #757575;
  color: 16px;
}
.eventPurchaseCredtisCreditsOrderSummaryRow {
  display: flex;
  justify-content: space-between;
  width: 300px;
}
