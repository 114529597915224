

.perview_demo_mobile_view_edit_profile{
    position: absolute;
    top: 16px;
    height: 94%;
    left: 17px;
    width: 90%;
    border-radius: 30px;
    overflow: hidden;
}

.perview_demo_mobile_view_edit_profile *::-webkit-scrollbar{
    display: none;
}

.perview_demo_mobile_view_edit_profile > div{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
}

.perview_demo_mobile_view_edit_profile > div::-webkit-scrollbar{
    display: none;
}

.live_demo_navbar_section{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 7px;
    box-sizing: border-box;
}

.live_demo_navbar_section > img{
    width: 30px;
    height: 30.741px;
    border-radius: 50%;
    background: #121212;
}

.live_demo_navbar_section > button{
    background-color: transparent;
    border-radius: 38px;
    color: #fff;
    font-family: "Gilroy-Light",sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 4px 13px;
    background: transparent;
    border: 1px solid hsla(0,0%,100%,.5);
}

.live_demo_main_creator_details{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    background: linear-gradient(180deg, #3E3E3E 0%, rgba(18, 18, 18, 0.00) 100%);
}

.live_demo_main_creator_details > img{
    border-radius: 50%;
    height: 121px;
    width: 122px;
    min-width: 122px; 
}
.live_demo_main_creator_details > p{
    text-align: center;
    margin: 0;
}
.live_demo_main_creator_details > div{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.live_demo_main_creator_details > div span{
    align-items: center;
    color: #e2e8f0;
    display: flex;
    font-family: "Gilroy-Regular",sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    gap: 2px;
    line-height: normal;
}
.about_section_new_creator_profile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #171E25;
}

/* Text types --------- */


.host_new_creator_page_outer_wrapper {
    width: 100vw;
    min-height: 100vh;
    background: #101010;
  }
  
  .host_outerframe_new_creator_page {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    /* margin-top: 22px; */
    gap: 20px;
    margin-bottom: 50px;
    min-height: 85vh;
    background-color: rgba(18, 18, 18);
  }
  
  /* text type creator profile page */
  
  .host_text_creator_profile_page-01 {
    color: #E2E8F0;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
  
  }
  
  .host_text_creator_profile_page-02 {
    color: #94A3B8;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    width: 100%;
  }
  
  .host_text_creator_profile_page-03 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
    color: #CBD5E1;
  
  }
  
  .host_text_creator_profile_page-04 {
    color: #94A3B8;
    font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  width: 100%;
  
  }
  
  .host_text_creator_profile_page-04 * {
    font: inherit !important;
    background-color: inherit !important;
    color: inherit !important;
  }
  
  .host_text_creator_profile_page-04 ul {
    padding-left: 20px;
  }
  
  .host_text_creator_profile_page-04 ol {
    padding-left: 20px;
  }
  
  .text_creator_profile_page-05 {
    color: #fff;
    font-family: " Gilroy-Bold", sans-serif;
    font-size: 24px;
    font-style: normal;
    line-height: normal;
  }
  
  /* Main section creator profile */
  
  .host_main_creator_details_creator_page {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 12px;
    overflow-wrap: break-word;
    /* background: linear-gradient(180deg, #3E3E3E 0%, rgba(18, 18, 18, 0) 100%); */
  }
  
  .host_main_creator_details_creator_page >div > img {
    width: 80px;
    min-width: 80px;
    height: 80px;
    min-height: 80px;
    border-radius: 50%;
  }
  
  .host_main_creator_details_creator_page > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    width: 60%;
    word-break: break-word;
  }
  
  .host_main_creator_details_creator_page > div > span {
    display: flex;
    align-items: center;
    gap: 8px;
  
    color: #e2e8f0;
    font-family: "Gilroy-Regular", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .host_main_creator_details_creator_page > div > span > span {
    font-size: 16px;
    color: Gray;
  }
  
  .button01_new_crator_profile {
    padding: 20px 40px;
    border-radius: 8px;
    background: #212121;
    cursor: pointer;
  
    color: #e2e8f0;
    font-family: " Gilroy-Medium", sans-serif;
    font-size: 24px;
    font-style: normal;
    line-height: normal;
    border: none;
  }
  
  .button01_new_crator_profile:hover {
    background: #323232;
  }
  
  .host_social-icons-new-creator-page {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 4px 0;
  }
  
  .host_social-icons-new-creator-page > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    /* background: #212121; */
    cursor: pointer;
  }
  
  /* About section ------------- */
  
  .host_about_section_new_creator_profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 713px;
    width: 328px;
    background-color: #12121200;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  /* Past and upcoming section */
  .host_past_and_upcoming_section_profile{
    display: flex;
    width: 80%;
    margin: 36px 0 0 ;
    gap: 20px;
  }
  .host_past_and_upcoming_section_profile button{
    background: transparent;
    border: transparent;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #475569;
    cursor: pointer;
  }
  .host_past_and_upcoming_section_profile .selected{
    color: #FF8A8A;
    text-decoration:  underline ;
  }
  /* Extra card sections --------------- */
  
  
  /* Other Services ---------------- */
  
  .other_services_new_creator_profile {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }
  
  .other_services_new_creator_profile > section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
  }
  
  .filter_option_new_creator_profile {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 12px 16px;
    border-radius: 4px;
    background: #212121;
    cursor: pointer;
  
    position: absolute;
    right: 0px;
    color: #e2e8f0;
    font-family: "Gilroy-Medium", sans-serif;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
  }
  
  .other_services_new_creator_profile > div {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 20px;
    max-height: 750px;
    overflow-y: auto;
    padding-right: 20px;
  }
  
  .other_services_new_creator_profile > div::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .other_services_new_creator_profile > div::-webkit-scrollbar-track {
    background: #000;
  }
  
  /* Handle */
  .other_services_new_creator_profile > div::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px;
  }
  
  /* Handle on hover */
  .other_services_new_creator_profile > div ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  /* reviews section  ------------- */
  
  .other_reviews_new_creator_profile {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }
  
  .other_reviews_new_creator_profile > div {
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow-x: auto;
  }
  
  .other_reviews_new_creator_profile > div::-webkit-scrollbar {
    display: none;
  }
  
  /* Extra section --------------- */
  .host_extra_cards_section_new_creator_profile {
    width: 328px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    /* justify-content: space-between; */
  }
  
  .host_extra_card_new_profile_page {
    display: flex;
    align-items: flex-start;
    padding: 5px;
    width: 346px;
    height: auto;
    box-sizing: border-box;
    flex-direction: column;
    border-radius: 8px;
    background: #212121;
    gap: 16px;
    position: relative;
    background: transparent;
    border: 1px solid #475569
  }
  
  .host_extra_card_new_profile_page > section {
    padding: 4px 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: #28292a;
    color: #e2e8f0;
    text-align: center;
    font-family: "Gilroy-Medium", sans-serif;
    font-size: 16px;
    font-style: normal;
    line-height: normal;
  }
  
  .host_extra_card_profile_details {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  
  .host_extra_card_profile_details > img {
    width: 100%;
    height: 187px;
    border-radius: 8px;
  }
  
  .host_extra_card_profile_details > section {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    flex-direction: column;
    width: 85%;
    overflow-wrap: break-word;
    margin-left: 10px;
  }
  
  .host_extra_card_profile_details > section > h2 {
    color: #fff;
    overflow: hidden;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    font-style: normal;
    width: 95%;
    /* line-height: normal; */
  }
  
  .host_extra_card_profile_details > section > div {
    display: flex;
    align-items: center;
    gap: 16px;
  
    color: #94a3b8;
    font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  
  }
  
  .host_extra_card_profile_details > section > div > span {
    display: flex;
    align-items: center;
    gap: 6px;
  }
  
  .host_extra_card_profile_details > section > div > span > img {
    width: 12px;
    height: 12px;
  }
  
  .host_extra_card_profile_details > section > section {
    color: #34d399;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .host_extra_card_profile_details > span {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    position: absolute;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
  }
  
  .host_extra_card_profile_details > span:hover {
    background-color: #363637;
  }
  @media (max-width : 767px) {
    .host_text_creator_profile_page-04 {
      font-size: 14px;
      width: 328px;
    }
  }
  
  @media (max-width: 600px) {
    .host_outerframe_new_creator_page {
      
      gap: 10px;
    }
  
    /* text types -----------------  */
  
    .host_text_creator_profile_page-01 {
      font-size: 24px;
    }
  
    .host_text_creator_profile_page-02 {
      font-size: 15px;
      /* margin: 14px 0; */
    }
  
    .host_text_creator_profile_page-03 {
      font-size: 20px;
    }
  
    .host_text_creator_profile_page-04 {
      font-size: 14px;
      width: 90%;
    }
  
    .text_creator_profile_page-05 {
      font-size: 20px;
    }
  
    /* main creator section -------------- */
  
    .host_main_creator_details_creator_page {
      gap: 20px;
      width: 100%;
    }
  
    .host_main_creator_details_creator_page > div {
      gap: 8px;
    }
  
    .host_main_creator_details_creator_page > div > span {
      font-size: 12px;
      gap: 2px;
    }
  
    .host_main_creator_details_creator_page > div > span span {
      font-size: 10px;
    }
  
    .host_main_creator_details_creator_page > img {
      width: 122px;
      height: 121px;
      border-radius: 8px;
      min-width: unset;
      min-height: unset;
    }
  
    .host_social-icons-new-creator-page {
      margin: 8px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0;
    }
  
    .host_social-icons-new-creator-page > div {
      width: 32px;
      height: 32px;
      padding: 8px;
    }
  
    .button01_new_crator_profile {
      width: 100%;
      padding: 12px 20px;
      box-sizing: border-box;
      font-size: 16px;
      margin-top: 12px;
    }
  
    /* about section ---------------- */
  
    .host_about_section_new_creator_profile{
      width: 100%;
    }
  
    /* other service section ----------- */
  
    .other_services_new_creator_profile {
      margin-top: 40px;
    }
  
    .other_services_new_creator_profile > div {
      grid-template-columns: repeat(2, 1fr);
      place-items: center;
      padding: unset;
    }
  
    /* other reviews -------------- */
  
    .other_reviews_new_creator_profile {
      margin-top: 40px;
    }
  
    .other_reviews_new_creator_profile > div {
      gap: 8px;
    }
  
    /* extra section -------------- */
  
    .host_extra_cards_section_new_creator_profile {
      flex-direction: column;
      gap: 15px;
      /* margin-top: 40px; */
      align-items: center;
      width: 90%;
    }
  
    .host_extra_card_new_profile_page {
      width: 328px;
      height: auto;
      padding: 8px;
      gap: 8px;
    }
  
    .host_extra_card_new_profile_page > section {
      font-size: 12px;
    }
  
    .host_extra_card_profile_details {
      justify-content: space-between;
    }
  
    .host_extra_card_profile_details > img {
      width: 100%;
      height: 177px;
    }
  
    .host_extra_card_profile_details > section {
      gap: 6px;
    }
  
    .host_extra_card_profile_details > section > h2 {
      font-size: 12px;
    }
  
    .host_extra_card_profile_details > section > div {
      font-size: 12px;
    }
  
    .host_extra_card_profile_details > section > section {
      font-size: 12px;
    }
  
    .host_extra_card_profile_details > span {
      width: 24px;
      height: 24px;
      /* right: 0; */
      /* bottom: -4px; */
      font-size: 12px;
    }
  
    .extra_cards_mobile_view_new_creator_profile {
      width: 100vw;
      padding: 8px 16px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 4px;
      background: #111010;
      position: fixed;
      z-index: 9999;
      bottom: 0;
      left: 0;
      box-sizing: border-box;
      color: white;
    }
  
    .extra_cards_mobile_view_new_creator_profile > h4 {
      color: #fff;
      font-family: "Gilroy-Regular", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  
    .extra_cards_mobile_view_new_creator_profile > span {
      color: var(--neutral-gray-400, #94a3b8);
      font-family: "Gilroy-Regular", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
  
      display: flex;
      align-items: center;
      gap: 5px;
    }
  
    .extra_cards_mobile_view_new_creator_profile > div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 4px;
    }
  
    .extra_cards_mobile_view_new_creator_profile > div > span {
      color: var(--success-green-500, #10b981);
      font-family: "Gilroy-Medium", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
    }
  
    .extra_cards_mobile_view_new_creator_profile > div > p {
      color: #fff;
      text-align: center;
      font-family: "Gilroy-Medium", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
  
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;
    }
  
    .extra_cards_mobile_view_new_creator_profile > div > p > span {
      width: 24px;
      height: 24px;
      background-color: #28292a;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  