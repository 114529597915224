.navbar_outside_container {
  width: 100%;
  background: #131825;
  height: 60px;
  position: sticky;
  top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  z-index: 99;
  display: flex;
  align-items: center;
  gap: 25px;
  flex-direction: row-reverse;
  box-sizing: border-box;
  padding-right: 40px;
}

.creators_navbar_image {
  width: 36px;
  height: 36px;
  border-radius: 100px;
  /* position: absolute;
  right: 40px; */
  top: 10px;
  cursor: pointer;
}

.navbar_outside_container > span {
  color: #f0f0f0;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.fancy_navbar_link01 {
  border-radius: 4px;
  border: 1px solid rgba(160, 160, 160, 0.5);
  padding: 8px 12px;

  color: #1e293b;
  text-align: center;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.fancy_navbar_link01:hover {
  background-color: red;
  border: 1px solid red;
}

.navbar_dashboard_button01 {
  border-radius: 41px;
  background: linear-gradient(160deg, #5f8bff 13.35%, #b468ff 90.3%);
  padding: 8px 20px;

  color: #f8fafc;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
}
.EventsnavbarChipsPopup {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: #12121280;
  z-index: 99;
}
.EventsnavBarChipsPopupContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 80%;
  overflow: auto;
  background: #fff;
  border-radius: 20px;
  scrollbar-width: none;
}

/* mobile ui  */

@media (max-width: 600px) {
  .navbar_outside_container {
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 4%;
    height: 69px;
    max-height: 69px;
  }

  .creators_navbar_image {
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }
}
