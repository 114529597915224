

/* dummy data CTA--------------------------- */

.cta_dummy_data {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}

.cta_dummy_data > span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 18px;
  color: #ffffff;
}

